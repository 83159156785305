import React, { useCallback, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { registerUser, setApiKey } from "../UserService/UserService";
import TextField from "../common/TextField";

type FormValues = {
  login: string;
  email: string;
  password: string;
  repeatedPassword: string;
}

type Props = {
  notifyError: (msg: string) => void;
  notifySuccess: (msg: string) => void;
}

const validationSchema = Yup.object<FormValues>({
  login: Yup.string()
    .required('Login required')
    .min(3, 'Login must be at least 3 characters long'),
  email: Yup.string()
    .required('E-mail required')
    .email('E-mail must be correct'),
  password: Yup.string()
    .required('Password Required'),
  repeatedPassword: Yup.string()
    .required('Repeat password required')
    .when("password", {
      is: (value: string) => !!(value && value.length > 0),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Passwords don't match!"
      )
    }),
});

const initialValues = { login: '', email: '', password: '', repeatedPassword: '' };

const Register: React.FC<Props> = (props) => {
  const { notifyError, notifySuccess } = props;

  const [isRegistered, setRegistered] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(async (event) => {
    setSubmitting(true);
    (await registerUser(event)).fold({
      left: () => {
        notifyError('Could not register new user!');
      },
      right: ({ apiKey }) => {
        setApiKey(apiKey);
        setRegistered(true);
        notifySuccess('Successfully registered.');
      }
    });
    setSubmitting(false);
  }, [notifyError, notifySuccess]);

  return (
    isRegistered ? <Redirect to="/login"/>
      : <div className="register-container">
        <Link to="/"><img className="sml-logo" src={`${process.env.PUBLIC_URL}/sml_logo_white.svg`}></img></Link>
        <div className="register-gradient">
          <div className="register-stripes"></div>
          <div className="register-box">
            <h4>Please sign up</h4>
            <div>
              <Formik initialValues={initialValues}
                      onSubmit={handleSubmit}
                      validationSchema={validationSchema}>
                <Form className="register-form">
                  <TextField type="text" name="login" placeholder="Login" className="form-control"/>
                  <TextField type="text" name="email" placeholder="Email" className="form-control"/>
                  <TextField type="password" name="password" placeholder="Password" className="form-control"/>
                  <TextField type="password" name="repeatedPassword" placeholder="Repeat password"
                             className="form-control"/>
                  <Link to="/recover-lost-password">Forgot password?</Link>
                  <input type="submit" value="Sign up" className="register-button" disabled={isSubmitting}/>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Register;
