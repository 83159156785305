import React from "react";
import LanguageSwitcher from "../../../i18n/components/LanguageSwitcher";
import { FormattedMessage, useIntl } from "react-intl";
import Newsletter from "../../../Newsletter/Newsletter";
import { Link } from "react-router-dom";
import { StaticRoutes } from "../../../common/Enums";

const Header: React.FC = () => {
  const { locale, formatMessage } = useIntl();
  return (
    <div className="header">
      <div className="header-gradient">
        <div className="header-logo">
          <div className="sml-logo">
            <Link to={`/${locale}` + formatMessage({ id: StaticRoutes.Home })}>
              <img src={require(`../../../assets/logo_new.png`)}></img>
            </Link>
          </div>
          <div>
            <LanguageSwitcher/>
          </div>
        </div>
        <div className="header-content">
          <h1 className="title"><FormattedMessage id="site.title"/></h1>
          <h3 className="subtitle"><FormattedMessage id="site.subtitle"/></h3>
          <div className="newsletter-container">
            <Link to={{ pathname: "https://mailchi.mp/softwaremill.com/mktc9rkwbu"}} target="_parent">
              <button className="sign-up-now">
                <FormattedMessage id="site.newsletter"/>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Header;
