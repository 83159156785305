import * as React from 'react';
import { WorkshopData } from "../../LandingPages/common/types";
import { FormattedHTMLMessage, FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { StaticRoutes } from "../../common/Enums";

const MainWorkshop: React.FC<{ data: WorkshopData }> = (props) => {
  const { titleId, descriptionId, logo, videoLink } = props.data;
  const { locale, formatMessage } = useIntl();

  return (
    <div className="main-workshop">
      <h1>
        <FormattedMessage id={titleId}/>
      </h1>
      <div className="video-container">
        <div className="youtube-outer-container">
          {videoLink && <Link to={{ pathname: "https://youtu.be/Jln3xsTpCs8" }} target="_parent">
            <img className="video-intro-image" src={require("../../assets/gatling_movie1.png")} alt="Performance tests in Gatling 1"></img>
            <div className="youtube-inner-container">
              <img className="youtube-button" src={require("../../assets/youtube.png")}></img>
            </div>
          </Link>}
        </div>
      </div>
      <div className="tile">
        <div className="main-workshop-row">
          <div className="column">
            <div className="card">
              <div className="card-content">
                <FormattedHTMLMessage id={descriptionId}/>
              </div>
            </div>
          </div>
          {logo && <div className="logo-column">
            <div className="card">
              <div className="card-content">
                <div className="workshop-logo">
                  <Link to={`/${locale}${formatMessage({ id: StaticRoutes.Gatling })}`}>
                    <img src={require(`../../assets/${logo}`)}></img>
                  </Link>
                </div>
              </div>
            </div>
          </div>}
        </div>
        <div className="main-workshop-row">
          <div>
            <Link to={{ pathname: "https://mailchi.mp/softwaremill.com/mktc9rkwbu"}} target="_parent">
              <button className="check-for-more">
                <FormattedMessage id="workshop.sign.up.now"/>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
};

export default MainWorkshop;
