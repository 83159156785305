import axios, { AxiosRequestConfig } from "axios";

const apiUrl: string = process.env.REACT_API_URL || '';

const delayForDevPurposes = async () => {
  await new Promise(resolve => setTimeout(resolve, 500));
};

type AxiosSecuredRequestConfig = AxiosRequestConfig & { securedRequest?: boolean };

const securedRequest = (config: AxiosSecuredRequestConfig) => {
  return config.securedRequest;
};

const requestHandler = (request: AxiosRequestConfig) => {
  if (securedRequest(request)) {
    const apiKey = localStorage.getItem('apiKey');
    request.headers['Authorization'] = `Bearer ${apiKey}`;
  }
  return request;
};

axios.interceptors.request.use(
  request => requestHandler(request)
);

const get = async <T, Err = any>(
  url: string,
  config: AxiosSecuredRequestConfig = { securedRequest: true }): Promise<T> => {

  await delayForDevPurposes();
  const { data } = await axios.get(`${apiUrl}${url}`, config);
  return data;
};

const post = async <V, T, Err = any>(
  url: string,
  requestData: V,
  config: AxiosSecuredRequestConfig = { securedRequest: true }): Promise<T> => {

  const { data } = await axios.post(`${apiUrl}${url}`, requestData, config);
  return data;
};

const put = async <V, T, Err = any>(
  url: string,
  requestData?: V,
  config: AxiosSecuredRequestConfig = { securedRequest: true }): Promise<T> => {

  const { data } = await axios.put(`${apiUrl}${url}`, requestData, config);
  return data;
};

export { get, post, put };
