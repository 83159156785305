import * as React from 'react';
import { useEffect } from 'react';
import { getPriceDetails } from "../SaleService/SaleService";
import { usePromise } from "react-use-promise-matcher";
import { PriceCheckDetails } from "../types/Types";
import styles from "./PriceDetails.module.scss";

type Props = {
  quantity: number;
  discountCode?: string;
}

const PriceDetails: React.FC<Props> = ({ quantity, discountCode }) => {
  const { result, load } = usePromise<PriceCheckDetails, Error>(() => getPriceDetails({ quantity, discountCode }));

  useEffect(() => {
    load();
  }, [quantity, discountCode]);

  return result.match({
    Loading: () => <></>,
    Rejected: () => <></>,
    Resolved: priceDetails => {
      const { itemTotal = 0, total = 0, discount, validatedDiscountCode } = priceDetails;
      return <div className={styles.priceDetailsContainer}>
        {validatedDiscountCode?.valid &&
        <div className={styles.amount}>Amount: {itemTotal.toFixed(2)} EUR</div>}
        {validatedDiscountCode?.valid && <div className={styles.discount}>Discount: {discount.toFixed(2)} EUR</div>}
        <div><strong>Total: {total.toFixed(2)} EUR</strong></div>
      </div>
    }
  });
};

export default PriceDetails;
