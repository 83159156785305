export enum AppLanguages {
  English = "en",
  Polish = "pl",
}

export enum StaticRoutes {
  Home = 'routes.home',
  Gatling = 'routes.gatling',
  AkkaTyped = 'routes.akka.typed',
  EventSourcing = 'routes.event.sourcing',
  AkkaCluster = 'routes.akka.cluster',
  AkkaStreams = 'routes.akka.streams'
}
