import React from 'react';
import './Footer.scss';
import { Version } from "../types/Types";
import { Either } from "ts-matches";
import Newsletter from "../Newsletter/Newsletter";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const Footer: React.FC<{ version: Either<string, Version> }> = ({ version }) => {
  return (
    <div id="footer" className="footer-container">
      <img className="footer-logo" src={`${process.env.PUBLIC_URL}/logo_sml_white.png`}></img>
      <div className="company-info">
        <p>© 2021 SoftwareMill Academy. All rights reserved.</p>
        <p><a href="mailto:academy@softwaremill.com">academy@softwaremill.com</a></p>
        <span className="app-version">
        {version.fold({
          left: () => <p>App Version: 1.0</p>,
          right: version => <p>Version: {version.buildDate}</p>
        })}
      </span>
      </div>
      <div className="footer-newsletter">
        <Link to={{ pathname: "https://mailchi.mp/softwaremill.com/mktc9rkwbu"}} target="_parent">
          <button className="sign-up-now">
            <FormattedMessage id="workshop.sign.up.now"/>
          </button>
        </Link>
      </div>
    </div>)
};

export default Footer;
