import React, { useCallback, useContext, useEffect, useState } from 'react';
import { changeProfileDetails } from '../UserService/UserService';
import { User } from '../types/Types';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { AppContext } from "../Contexts/AppContext";
import TextField from "../common/TextField";

type FormValues = {
  login: string;
  email: string;
}

type Props = {
  notifyError: (msg: string) => void;
  notifySuccess: (msg: string) => void;
  onUserUpdated: (user: User) => void;
}

const validationSchema = Yup.object<FormValues>({
  login: Yup.string()
    .required('Login required'),
  email: Yup.string()
    .required('Email required'),
});

const ProfileDetails: React.FC<Props> = (props) => {
  const { login, email } = useContext(AppContext).user;
  const { notifyError, notifySuccess, onUserUpdated } = props;
  const [isSubmitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(async ({ login, email }) => {
    const profileDetailsResult = await changeProfileDetails({ login, email });

    profileDetailsResult.fold({
      left: (error: string) => {
        notifyError('Could not change profile details!');
        console.error(error);
      },
      right: () => {
        onUserUpdated({ email, login });
        notifySuccess('Profile details changed!');
      }
    });
  }, []);

  return (
    <div className="ProfileDetails">
      <h4>Profile details</h4>
      <Formik initialValues={{ login, email }}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}>
        <Form className="common-form">
          <TextField type="text" name="login" placeholder="Login" className="form-control"/>
          <TextField type="text" name="email" placeholder="Email" className="form-control"/>
          <input type="submit" value="Update profile data" className="btn btn-primary" disabled={isSubmitting}/>
        </Form>
      </Formik>
    </div>
  );
};

export default ProfileDetails;
