import * as React from 'react'
import { useState } from 'react'
import { Form, Formik } from "formik";
import TextField from "../common/TextField";
import * as Yup from "yup";
import { registerUser } from "../UserService/UserService";

type FormValues = {
  login: string;
  email: string;
  password: string;
  repeatedPassword: string;
}

const initialValues: FormValues = { login: '', email: '', password: '', repeatedPassword: '' };

const validationSchema = Yup.object<FormValues>({
  login: Yup.string()
    .required('Login required')
    .min(3, 'Login must be at least 3 characters long'),
  email: Yup.string()
    .required('E-mail required')
    .email('E-mail must be correct'),
  password: Yup.string()
    .required('Password Required'),
  repeatedPassword: Yup.string()
    .required('Repeat password required')
    .when("password", {
      is: (value: string) => !!(value && value.length > 0),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Passwords don't match!"
      )
    }),
});

type Props = {
  onRegisterSuccess: (apiKey: string) => void;
  onRegisterFailure?: (errorMessage: string) => void;
  formClassNames?: string[];
}

const SimpleRegister: React.FC<Props> = props => {

  const { onRegisterSuccess, onRegisterFailure } = props;
  const [isSubmitting, setSubmitting] = useState(false);
  const formEffectiveClassNames = (props.formClassNames ? props.formClassNames : ["common-form"]).join(" ");

  const handleSubmit = async (formValues: FormValues) => {
    setSubmitting(true);
    (await registerUser(formValues)).fold({
      left: (errorMessage: string) => {
        if (onRegisterFailure) {
          onRegisterFailure(errorMessage);
        }
      },
      right: ({ apiKey }) => {
        onRegisterSuccess(apiKey);
      }
    });
    setSubmitting(false);
  };

  return <>
    <Formik initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}>
      <Form className={formEffectiveClassNames}>
        <TextField type="text" name="login" placeholder="Login" className="form-control"/>
        <TextField type="text" name="email" placeholder="Email" className="form-control"/>
        <TextField type="password" name="password" placeholder="Password" className="form-control"/>
        <TextField type="password" name="repeatedPassword" placeholder="Repeat password" className="form-control"/>
        <input type="submit" value="Sign up" className="btn btn-primary" disabled={isSubmitting}/>
      </Form>
    </Formik>
  </>
};

export default SimpleRegister
