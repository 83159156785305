import React from "react";
import Menu from "../Menu";
import WorkshopDescription from "../common/WorkshopDescription/WorkshopDescription";
import { andrzej, eventSourcingAgenda, eventSourcingDescription } from "../data";
import Agenda from "../common/Agenda/Agenda";
import Trainer from "../Trainer";
import Testimonials from "../Testimonials";
import WorkshopHeader from "../common/Header/WorkshopHeader";
import OtherWorkshops from "../../Welcome/Workshops/OtherWorkshops";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const EventSourcing: React.FC = () => {
  return (
    <div className="event-sourcing-container">
      <WorkshopHeader titleId="event.sourcing.title"
                      subtitleId="event.sourcing.subtitle"
                      trainerImage="andrzej.png"
                      mailchimp="https://mailchi.mp/softwaremill.com/mktc9rkwbu"/>
      <Menu/>
      <div id="content" className="workshop-content">
        <WorkshopDescription data={eventSourcingDescription}/>
        <div id="videos" className="videos">
          <h1><FormattedMessage id="workshop.videos"/></h1>
          <div className="videos-container">
            <Link to={{ pathname: "https://youtu.be/hq5oa9kKslg" }} target="_parent">
              <div className="youtube-outer-container">
              <img className="video-intro-image" src={require("../../assets/andrzej_rome.png")}
                   alt="Event Souring: what could go wrong?"></img>
                <div className="youtube-inner-container">
                  <img className="youtube-button" src={require("../../assets/youtube.png")}></img>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div id="agenda">
          <Agenda data={eventSourcingAgenda}/>
        </div>
      </div>
      <div id="trainer">
        <Trainer data={andrzej}/>
      </div>
      <Testimonials/>
      <OtherWorkshops theme="Light"/>
    </div>
  )
};

export default EventSourcing;
