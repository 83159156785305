import * as React from 'react';
import { useContext, useState } from 'react';
import { Redirect, useParams } from "react-router-dom";
import { redeemCode } from "../CourseService/CourseService";
import { AppContext } from "../Contexts/AppContext";
import RedeemCodeAsLoggedInUser from "./RedeemCodeAsLoggedInUser";
import RedeemCodeAsAnonymousUser from "./RedeemCodeAsAnonymousUser";

type Props = {
  notifySuccess: (msg: string) => void;
  notifyError: (msg: string) => void;
  onLoggedIn: () => Promise<void>;
}

const RedeemCode: React.FC<Props> = props => {

  const { code } = useParams<{code: string}>();
  const { isLoggedIn } = useContext(AppContext);
  const { notifySuccess, notifyError, onLoggedIn } = props;
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const redeemCodeHandle = () => {
    redeemCode(code!)
      .then(() => {
          notifySuccess("You have successfully redeemed the code. Course was assigned to given account.");
          setShouldRedirect(true);
        }
      ).catch(error => {
        const statusCode: number = error.response.status;
        if (statusCode === 404) {
          notifyError("Ups! Looks like the code is invalid")
        } else if (statusCode === 409) {
          notifyError("You already own this course!")
        } else if (statusCode === 410) {
          notifyError("The code you are trying to redeem was already used");
        } else {
          notifyError("Unknown exception happened");
        }
      }
    );
  };
  return shouldRedirect ? <Redirect to="/my-courses"/> :
    <>
      {isLoggedIn ? <RedeemCodeAsLoggedInUser redeemCodeHandle={redeemCodeHandle}/> :
        <RedeemCodeAsAnonymousUser onLoggedIn={onLoggedIn} redeemCodeHandle={redeemCodeHandle}
                                   notifyError={notifyError}/>}
    </>
};

export default RedeemCode;
