import * as React from "react";
import { Form, Formik } from "formik";
import TextField from "../common/TextField";
import { useRef } from "react";
import { FormattedMessage } from "react-intl";

type Props = {
  mailchimp: string;
}
const Newsletter: React.FC<Props> = ({ mailchimp }) => {
  const formEl = useRef(null);

  const handleSubmit = () => {
    // @ts-ignore
    formEl.current.submit();
  };
  return (
    <div>
      <div className="newsletter">
        <p><FormattedMessage id="site.newsletter"/></p>
        <Formik initialValues={{ email: '' }} onSubmit={handleSubmit}>
          <Form className="subscribe-form"
                action={mailchimp}
                target="_blank"
                method="POST" ref={formEl}>
            <TextField type="text" name="EMAIL" placeholder="e-mail" className="text-field"/>
            <input type="submit" value="" className="subscribe-button" disabled={false} autoFocus={false}
                   required={false}/>
          </Form>
        </Formik>
      </div>
    </div>
  )
};

export default Newsletter;
