import * as React from 'react';
import styles from './ProgressBar.module.scss';
import { CourseChapter } from "../../types/Types";

const ProgressBar: React.FC<{ chapter: CourseChapter }> = ({ chapter }) => {
  const pagesNumber = chapter.modules.map(m => m.pages.length).reduce((a, b) => a + b, 0);
  const finishedPages = chapter.modules.flatMap(m => m.pages).filter(p => p.finished).length;

  const progressWidth = finishedPages / pagesNumber * 200;

  return (
    <div>
      <div className={styles.progressBar}>
        <div className="progress">
          <div className={`progress-bar ${styles.progressBarColor}`} role="progress"
               style={{ width: progressWidth }}></div>
        </div>
      </div>
    </div>
  )
};

export default ProgressBar;
