import * as React from "react";
import Menu from "../Menu";
import Agenda from "../common/Agenda/Agenda";
import { akkaTypedAgenda, akkaTypedDescription, andrzej } from "../data";
import WorkshopDescription from "../common/WorkshopDescription/WorkshopDescription";
import Trainer from "../Trainer";
import Testimonials from "../Testimonials";
import WorkshopHeader from "../common/Header/WorkshopHeader";
import OtherWorkshops from "../../Welcome/Workshops/OtherWorkshops";

const AkkaTyped: React.FC = () => {
  return (
    <div className="akka-container">
      <WorkshopHeader titleId="akka.typed.title"
              subtitleId="akka.typed.subtitle"
              trainerImage="andrzej.png"
              mailchimp="https://mailchi.mp/softwaremill.com/g0xleykbkf"/>
      <Menu/>
      <div id="content" className="workshop-content">
        <WorkshopDescription data={akkaTypedDescription}/>
        <div id="videos" className="videos">

        </div>
        <div id="agenda">
          <Agenda data={akkaTypedAgenda}/>
        </div>
      </div>
      <div id="trainer">
        <Trainer data={andrzej}/>
      </div>
      <Testimonials/>
      <OtherWorkshops theme="Light"/>
    </div>
  )
};

export default AkkaTyped;
