import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { AppLanguages } from "../../common/Enums";
import { useIntl } from "react-intl";
import { appStrings } from "..";

const LanguageSwitcher: React.FC = () => {
  const { pathname } = useLocation();
  const { locale, messages } = useIntl();

  const getMatchingRoute = (language: string) => {
    const [, , route] = pathname.split("/");
    const routeKey = Object.keys(messages).find(key => messages[key] === `/${route}`);

    const matchingRoute = appStrings[language][routeKey];
    return `/${language}` +matchingRoute;
  };

  return (
    <div className="language-switcher">
      {Object.keys(AppLanguages).map(lang => (
        <div>
          <Link to={getMatchingRoute(AppLanguages[lang])}>
            {(AppLanguages[lang] as string).toUpperCase()}
          </Link>
        </div>
      ))}
    </div>
  )
};

export default LanguageSwitcher;
