import * as React from 'react';
import { useCallback,useEffect, useState } from 'react';
import { CreateOrderInput } from "../types/Types";
import { createOrder } from "../SaleService/SaleService";
import { PayPalButton } from "../PayPal/PayPalButton";
import { FormValues } from "./BuyForm";

type Props = {
  paymentEnabled: boolean;
  values: FormValues;
  onError?: Function;
  onApprove?: (data: unknown, actions: unknown) => void;
  onClick?: (data: unknown, actions: unknown) => void
  onCancel?: (data: unknown, actions: unknown) => void
}

const PaymentButton: React.FC<Props> = props => {

  const options = {
    // TODO consider how to parameterize it when going PROD
    clientId: "Aaa7Pue3FgX0v-tPaILbhZoM4Ud8WbwRLsG5tnm3pba5sd9jWHB6okJfSB9VFvXF37F0znJ58jF8HgTH",
    currency: "EUR",
    disableFunding: "card"
  };

  const [initActions, setInitActions] = useState<InitActions>();

  useEffect(() => {
    if (initActions) {
      if (props.paymentEnabled) {
        initActions.enable();
      } else {
        initActions.disable()
      }
    }
  }, [props.paymentEnabled, initActions]);

  const onInitHandler = (data: any, actions: any) => {
    setInitActions(actions);
  };

  const createOrderHandler = useCallback(async (data: any, actions: any) => {
    const createOrderInput: CreateOrderInput = {
      email: props.values.email,
      firstName: props.values.firstName,
      lastName: props.values.lastName,
      addressLine1: props.values.addressLine1,
      addressLine2: props.values.addressLine2,
      postalCode: props.values.postalCode,
      adminArea: props.values.adminArea,
      countryCode: props.values.country,
      comment: props.values.comment,
      company: props.values.company ? props.values.companyName : undefined,
      taxNumber: props.values.company ? props.values.taxNumber : undefined,
      quantity: props.values.quantity,
      discountCode: props.values.discountCode,
    };

    const { orderID } = await createOrder(createOrderInput);
    return orderID;

  }, [props.values]);

  return <PayPalButton
    onInit={onInitHandler}
    createOrder={createOrderHandler}
    onClick={props.onClick}
    onCancel={props.onCancel}
    onError={props.onError}
    onApprove={props.onApprove}
    options={options}
  />

};

type InitActions = {
  enable: () => void;
  disable: () => void;
}

export default PaymentButton;
