import * as React from "react";
import { useEffect } from "react";
import FormattedMessage from "react-intl/lib/components/message";

const Menu: React.FC = () => {

  useEffect(() => {
    window.addEventListener('scroll', checkMenuPosition);
  });

  const checkMenuPosition = () => {
    const menu = document.getElementById("menu");
    if (menu) {
      if (window.pageYOffset > menu.offsetTop) {
        menu.classList.add("sticky");
      } else {
        menu.classList.remove("sticky");
      }
    }
  };

  return (
    <div id="menu" className="menu">
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
              <a className="nav-link" href="#">Info<span className="sr-only">(current)</span></a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#agenda"><FormattedMessage id="menu.agenda"/></a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#videos"><FormattedMessage id="menu.videos"/></a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#trainer"><FormattedMessage id="menu.trainer"/></a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#tickets"><FormattedMessage id="menu.tickets"/></a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#footer"><FormattedMessage id="menu.other.workshops"/></a>
            </li>
          </ul>
        </div>
      </nav>

    </div>
  )
};

export default Menu;
