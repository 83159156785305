import { get, post } from "../Api/api";
import {
  CreateOrderInput,
  CreateOrderOutput,
  GetActiveSaleOutput,
  PriceCheckDetails,
  PriceCheckInput
} from "../types/Types";

const salesUrl = '/api/v1/sales';

export const getActiveSale = async (): Promise<GetActiveSaleOutput> => await get<GetActiveSaleOutput>(`${salesUrl}`);

export const getPriceDetails = async (input: PriceCheckInput): Promise<PriceCheckDetails> => {
  const { quantity, discountCode } = input;
  return await get<PriceCheckDetails>(`${salesUrl}/price`, { params: { quantity, discountCode } });
};

export const createOrder = async (input: CreateOrderInput): Promise<CreateOrderOutput> =>
  await post<CreateOrderInput, CreateOrderOutput>(`${salesUrl}/order`, input);
