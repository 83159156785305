import * as React from 'react';

type Props = {
  payPalOrderId: string;
}

const PaymentCompleted: React.FC<Props> = props => {

  return <div className="container">
    <h1 className="text-monospace mb-5 mt-5">The payment was approved</h1>
    <p>PayPal Order Identifier: <strong>{props.payPalOrderId}</strong></p>
    <p>Now we will try to capture the funds. If everything goes well then expect the email soon. In case you didn't get
      anything in longer period of time then check your <strong>SPAM</strong> folder before contacting us.</p>
  </div>

};

export default PaymentCompleted;
