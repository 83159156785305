import * as React from 'react';
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";
import { Switch, RouteComponentProps } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../Contexts/AppContext";
import MyCourses from "../MyCourses/MyCourses";
import CourseRoute from "../Course/CourseRoute";

type Props = RouteComponentProps<void>;

const MyCoursesRoute: React.FC<Props> = ({ match }) => {
  const { isLoggedIn } = useContext(AppContext);

  return (
    <Switch>
      <ProtectedRoute isLoggedIn={isLoggedIn} exact path={`${match.path}`} component={MyCourses}/>
      <ProtectedRoute isLoggedIn={isLoggedIn} path={`${match.path}/:coursePath`}
                      component={CourseRoute}/>
    </Switch>
  )
};

export default MyCoursesRoute;
