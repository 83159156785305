import React from "react";
import LanguageSwitcher from "../../../i18n/components/LanguageSwitcher";
import { FormattedMessage, useIntl } from "react-intl";
import Newsletter from "../../../Newsletter/Newsletter";
import { Link } from "react-router-dom";
import { StaticRoutes } from "../../../common/Enums";

type Props = {
  titleId: string;
  subtitleId: string;
  trainerImage: string;
  mailchimp: string;
  eveneaTickets?: string;
}

const WorkshopHeader: React.FC<Props> = ({ titleId, subtitleId, trainerImage, mailchimp, eveneaTickets }) => {
  const { locale, formatMessage } = useIntl();
  return (
    <div className="workshop-header">
      <div className="header-gradient">
        <div className="header-logo">
          <div className="sml-logo">
            <Link to={`/${locale}` + formatMessage({ id: StaticRoutes.Home })}>
              <img src={require(`../../../assets/logo_new.png`)}></img>
            </Link>
          </div>
          <div>
            <LanguageSwitcher/>
          </div>
        </div>
        <div className="workshop-header-content">
          <div className="header-titles">
            <h1 className="title"><FormattedMessage id={titleId}/></h1>
            <h3 className="subtitle"><FormattedMessage id={subtitleId}/></h3>
            {eveneaTickets ? <div>
              <Link to={{ pathname: eveneaTickets }} target="_parent">
                <button className="tickets-button-light">
                  <FormattedMessage id="workshop.buy.ticket"/>
                </button>
              </Link>
            </div> : <></>}
            <div className="newsletter-container">
              <Newsletter mailchimp={mailchimp}/>
            </div>
          </div>
          <div className="workshop-header-trainer">
            <img src={require(`../../../assets/${trainerImage}`)}/>
          </div>
        </div>
      </div>
    </div>
  )
};

export default WorkshopHeader;
