import * as React from 'react'
import SimpleLogin from "./SimpleLogin";

type Props = {
  onLoginSuccess: (apiKey: string) => void;
  notifyError: (msg: string) => void;
}

const LoginAndRedeem: React.FC<Props> = props => {

  const handleFailure = () => {
    props.notifyError("Incorrect login/email or password!");
  };

  return <>
    <p className="font-weight-bold text-monospace">Login to account to which you want assign the course to:</p>
    <SimpleLogin onLoginSuccess={props.onLoginSuccess} onLoginFailure={handleFailure}
                 formClassNames={["common-form m-auto"]}/>
  </>
};

export default LoginAndRedeem
