import * as React from 'react';
import { ErrorMessage, Field, Form, Formik, useFormikContext } from "formik";
import TextField from "../common/TextField";
import * as Yup from "yup";
import CountryCodes from "./CountryCodes";
import PriceDetails from "./PriceDetails";
import PaymentButton from "./PaymentButton";
import styles from './BuyForm.module.scss';

type Props = {
  onSuccess: (data: any, actions: any) => void;
  notifyError: (msg: string) => void;
}

const BuyForm: React.FC<Props> = props => {

  const { onSuccess: parentSuccessHandler, notifyError } = props;

  const initialValues: FormValues = {
    company: false,
    firstName: '',
    lastName: '',
    companyName: '',
    taxNumber: '',
    email: '',
    addressLine1: '',
    addressLine2: '',
    postalCode: '',
    adminArea: '',
    country: '',
    comment: '',
    discountCode: '',
    quantity: 1,
  };

  const validationSchema = Yup.object<FormValues>({
    firstName: Yup.string()
      .required('First name is required')
      .min(2, 'Name must be at least 2 characters long'),
    lastName: Yup.string()
      .required('Last name is required')
      .min(2, 'Name must be at least 2 characters long'),
    email: Yup.string()
      .required('E-mail required')
      .email('E-mail must be correct'),
    addressLine1: Yup.string()
      .required('Address is required')
      .min(5, "It doesn't look like valid address"),
    postalCode: Yup.string()
      .required("Please provide postal code"),
    adminArea: Yup.string()
      .required("Please give city or admin area name"),
    country: Yup.string()
      .required("Select a country"),
    quantity: Yup.number()
      .required()
      .min(1)
      .max(10, "If you want to order more please contact with us!"),
    company: Yup.boolean(),
    companyName: Yup.string()
      .when("company", {
        is: (value: boolean) => value,
        then: Yup.string().required("Please specify company name")
      }),
    taxNumber: Yup.string()
      .when("company", {
        is: (value: boolean) => value,
        then: Yup.string().required("Please specify tax number")
      })
  });

  const handleCancel = () => {
    notifyError("PayPal window was closed. No worries - In case payment was completed, we will deliver your purchase.");
  };

  const handleError = () => {
    notifyError("Unexpected error occurred. No worries - In case payment was completed, we will deliver your purchase.");
  };

  const CompanyDetails = () => {
    const { values } = useFormikContext<{ company: string }>();
    const isCompany = values.company;
    return isCompany ?
      <div className="form-row">
        <div className="col-md-7">
          <div className="form-group">
            <TextField type="text" name="companyName" label="Company name" className="form-control"/>
          </div>
        </div>
        <div className="col-md-5">
          <div className="form-group">
            <TextField type="text" name="taxNumber" label="Tax number" className="form-control"/>
          </div>
        </div>
      </div> : <></>
  };

  return <div className="Purchase">
    <div className="container">
      <div className="row">
        <div className="col-md text-center">
          <img src={`${process.env.PUBLIC_URL}/sml_2.png`} className="sml-logo" alt="SoftwareMill"/>
        </div>
      </div>

      <Formik initialValues={initialValues}
              onSubmit={() => {
              }}
              validationSchema={validationSchema}
              validateOnMount={true}>
        {({ values, isValid, submitForm }) =>
          <Form>
            <div className="row justify-content-between">
              <div className="col-lg-6">
                <small id="emailHelp" className="form-text text-muted">Please specify correct email as this is where
                  we'll deliver the purchase</small>
                <div className="form-group">
                  <TextField type="text" name="email" label="E-mail" className="form-control"/>
                </div>
                <div className="form-row">
                  <div className="col-md">
                    <div className="form-group">
                      <TextField type="text" name="firstName" label="First Name" className="form-control"/>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-group">
                      <TextField type="text" name="lastName" label="Last Name" className="form-control"/>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <TextField type="checkbox" name="company" label="Buying as a company?"
                             className={styles.companyCheckbox}/>
                </div>
                <CompanyDetails/>
                <div className="form-row">
                  <div className="col-md-7">
                    <div className="form-group">
                      <TextField type="text" name="addressLine1" label="Address line 1" className="form-control"/>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="form-group">
                      <TextField type="text" name="addressLine2" label="Address line 2" className="form-control"/>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-7">
                    <div className="form-group">
                      <TextField type="text" name="postalCode" label="Postal code" className="form-control"/>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="form-group">
                      <TextField type="text" name="adminArea" label="City" className="form-control"/>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="country">Country</label>
                  <Field as="select" name="country" className="form-control">
                    <option key="emptyOne" value="">---</option>
                    {CountryCodes.map(value =>
                      <option key={value.isoCode} value={value.isoCode}>{value.countryName}</option>
                    )}
                  </Field>
                  <ErrorMessage name="country">{msg => <div className="validation-message">{msg}</div>}</ErrorMessage>
                </div>
              </div>
              <div className="col-lg-5">
                <div className={styles.priceDetailsStyle}>
                  <fieldset className={styles.fieldsetMargin}>
                    <legend>Price details</legend>
                    <div className="form-group">
                      <TextField type="number" name="quantity" min="1" className="form-control" label="Quantity"/>
                    </div>
                    <div className="form-group">
                      <TextField type="text" name="discountCode" className="form-control" label="Discount code"/>
                    </div>
                  </fieldset>

                  <PriceDetails quantity={values.quantity} discountCode={values.discountCode}/>
                </div>
              </div>

            </div>


            <div className="row">
              <div className="col-md">
                <div className="form-group">
                  <TextField type="textarea" name="comment" className="form-control" label="Comment"/>
                </div>
                <div className="text-center mt-5">
                  <PaymentButton paymentEnabled={isValid} values={values} onClick={submitForm} onCancel={handleCancel}
                                 onError={handleError} onApprove={parentSuccessHandler}/>
                </div>
              </div>
            </div>

          </Form>}
      </Formik>

    </div>
  </div>;
};

export type FormValues = {
  email: string,
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2?: string;
  postalCode: string;
  adminArea: string;
  country: string;
  comment?: string;
  quantity: number;
  discountCode?: string;
  company: boolean;
  companyName?: string;
  taxNumber?: string;
}


export default BuyForm;
