import * as React from 'react';
import styles from './CourseCard.module.scss';
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { Course } from "../types/Types";
import { getCourse } from "../CourseService/CourseService";
import { usePromise } from "react-use-promise-matcher";
import Spinner from "../Spinner/Spinner";
import ErrorMessage from "../common/ErrorMessage";

type Props = {
  course: {
    name: string;
    path: string;
    imageUrl?: string;
    description: string;
  }
}

const CourseCard: React.FC<Props> = (props) => {
  const { name, path, imageUrl, description } = props.course;
  const { result, load } = usePromise<Course, Error>(() => getCourse(path));

  useEffect(() => {
    load();
  }, []);

  return result.match({
    Loading: () => <></>,
    Rejected: error => <ErrorMessage error={error.message}/>,
    Resolved: course =>
      <div className="card bg-light mb-3">
        <div className={styles.courseCard}>
          <img className="card-img-top"
               src={`${process.env.PUBLIC_URL}/${imageUrl}`}
               alt="Couse Image"
          ></img>
          <div className="card-body">
            <h5 className="card-title">{name}</h5>
            <p className="card-text">{description}</p>
            <div className={styles.cardBottom}>
              <Link to={`/my-courses/${path}/${course.progress.chapterPath}`}>
                <button className="btn btn-danger">Go to course</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
  });
};

export default CourseCard;
