import * as React from "react";
import Menu from "../Menu";
import Agenda from "../common/Agenda/Agenda";
import {
  akkaClusterAgenda,
  akkaClusterDescription, andrzej,
} from "../data";
import WorkshopDescription from "../common/WorkshopDescription/WorkshopDescription";
import Trainer from "../Trainer";
import Testimonials from "../Testimonials";
import WorkshopHeader from "../common/Header/WorkshopHeader";
import OtherWorkshops from "../../Welcome/Workshops/OtherWorkshops";

const AkkaCuster: React.FC = () => {
  return (
    <div className="akka-container">
      <WorkshopHeader titleId="akka.cluster.title"
                      subtitleId="akka.cluster.subtitle"
                      trainerImage="andrzej.png"
                      mailchimp="https://mailchi.mp/softwaremill.com/6wt91164h8"/>
      <Menu/>
      <div id="content" className="workshop-content">
        <WorkshopDescription data={akkaClusterDescription}/>
        <div id="agenda">
          <Agenda data={akkaClusterAgenda}/>
        </div>
      </div>
      <div id="trainer">
        <Trainer data={andrzej}/>
      </div>
      <Testimonials/>
      <OtherWorkshops theme="Light"/>
    </div>
  )
};

export default AkkaCuster;
