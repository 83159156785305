import * as React from 'react';
import { RouteComponentProps, Switch } from 'react-router-dom';
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";
import { useContext } from "react";
import { AppContext } from "../Contexts/AppContext";
import Page from "./Chapter/Page/Page";

type Props = RouteComponentProps<void>;

const PageRoute: React.FC<Props> = ({ match }) => {
  const { isLoggedIn } = useContext(AppContext);

  return (
    <div>
      <Switch>
        <ProtectedRoute isLoggedIn={isLoggedIn} path={`${match.path}/:pagePath`} component={Page}/>
      </Switch>
    </div>
  )
};

export default PageRoute;
