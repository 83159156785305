import * as React from 'react'
import LoginAndRedeem from "./LoginAndRedeem";
import RegisterAndRedeem from "./RegisterAndRedeem";
import { setApiKey } from "../UserService/UserService";

type Props = {
  redeemCodeHandle: () => void;
  notifyError: (msg: string) => void;
  onLoggedIn: () => Promise<void>;
}

const RedeemCodeAsAnonymousUser: React.FC<Props> = props => {

  const { redeemCodeHandle, notifyError, onLoggedIn } = props;

  const loginAndRedeemCode = (apiKey: string) => {
    setApiKey(apiKey);
    onLoggedIn().then(() => redeemCodeHandle());
  };

  return <div className="container text-center mt-5">
    <img src={`${process.env.PUBLIC_URL}/sml_2.png`} className="sml-logo mb-5" alt="SoftwareMill"/>
    <LoginAndRedeem onLoginSuccess={loginAndRedeemCode} notifyError={notifyError}/>
    <hr/>
    <p>OR</p>
    <hr/>
    <RegisterAndRedeem onRegisterSuccess={loginAndRedeemCode} notifyError={notifyError}/>
  </div>
};
export default RedeemCodeAsAnonymousUser;
