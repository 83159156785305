import * as React from 'react'
import { useState } from 'react'
import { Form, Formik } from "formik";
import TextField from "../common/TextField";
import * as Yup from "yup";
import { login } from "../UserService/UserService";

type FormValues = {
  loginOrEmail: string;
  password: string;
}

const initialValues: FormValues = { loginOrEmail: '', password: '' };

const validationSchema = Yup.object<FormValues>({
  loginOrEmail: Yup.string()
    .required('Login or Email Required'),
  password: Yup.string()
    .required('Password Required'),
});

type Props = {
  onLoginSuccess: (apiKey: string) => void;
  onLoginFailure?: (errorMessage: string) => void;
  formClassNames?: string[];
}

const SimpleLogin: React.FC<Props> = props => {

  const { onLoginSuccess, onLoginFailure } = props;
  const [isSubmitting, setSubmitting] = useState(false);
  const formEffectiveClassNames = (props.formClassNames ? props.formClassNames : ["common-form"]).join(" ");

  const handleSubmit = async (formValues: FormValues) => {
    setSubmitting(true);
    const { loginOrEmail, password } = formValues;
    (await login({ loginOrEmail, password })).fold({
      left: (errorMessage: string) => {
        if (onLoginFailure) {
          onLoginFailure(errorMessage);
        }
      },
      right: ({ apiKey }) => onLoginSuccess(apiKey)
    });
    setSubmitting(false);
  };

  return <>
    <Formik initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}>
      <Form className={formEffectiveClassNames}>
        <TextField type="text" name="loginOrEmail" placeholder="Login or Email" className="form-control"/>
        <TextField type="password" name="password" placeholder="Password" className="form-control"/>
        <input type="submit" value="Log in" className="btn btn-primary" disabled={isSubmitting}/>
      </Form>
    </Formik>
  </>

};

export default SimpleLogin
