import React from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";

type AgendaItem = {
  chapterId: string;
  title: string;
  items: string[];
}

type Props = {
  data: AgendaItem[];
}

const ChapterItem: React.FC<{ item: string }> = ({ item }) => {
  return (
    <div>
      <div className="agenda-content">
        <ul>
          <li><FormattedHTMLMessage id={item}/></li>
        </ul>
      </div>
    </div>
  )
};

const AgendaChapter: React.FC<{ chapter: string, chapterNum: number, items: string[] }> =
  ({ chapter, items }) => {
    return (
      <>
        <p className="agenda-item">
          <span>
            <FormattedMessage id={chapter}/>
          </span>
        </p>
        {items.map((item, ix) => <ChapterItem key={ix} item={item} />)}
      </>
    )
  };

const Agenda: React.FC<Props> = ({ data }) => {
  return (
    <div className="agenda">
      <h1>Agenda</h1>
      {data.map((i, ix) =>
        <AgendaChapter key={ix} chapter={i.chapterId} items={i.items} chapterNum={ix}/>)}
    </div>
  )
};

export default Agenda;
