import { LanguageStrings } from "./en";

export const pl: LanguageStrings = {
  'routes.home': '/',
  'routes.gatling': '/gatling-warsztat',
  'routes.akka.typed': '/akka-typed-warsztat',
  'routes.event.sourcing': '/event-sourcing-warsztat',
  'routes.akka.cluster': '/akka-cluster-warsztat',

  "site.title": "Akademia SoftwareMill",
  "site.subtitle": "Zdobądź praktyczną wiedzę z Programowania Funkcyjnego i Testowania pod okiem doświadczonych trenerów.",
  "footer.softwaremill": "© 2020 SoftwareMill Academy. Wszystkie prawa zastrzeżone.",
  "site.newsletter": "Dołącz do nas!",
  "site.other.workshops": "Nasze warsztaty",
  "site.other.workshops.akka.title": "Podstawy Akka Typed",
  "site.other.workshops.akka.subtitle": "Chciałbyś poznać Akka Typed?",
  "site.other.workshops.es.title": "Reactive Event Sourcing w Javie",
  "site.other.workshops.es.subtitle": "Chciałbyś nauczyć się Event Sourcing'u z Akka Persistence?",
  "site.other.workshops.akka.cluster.title": "Podstawy Akka Cluster",
  "site.other.workshops.akka.cluster.subtitle": "Chciałbyś poznać Akka Cluster?",
  "site.other.workshops.gatling.title": "Performance Testy w Gatlingu",
  "site.other.workshops.gatling.subtitle": "Chciałbyś poznać performance testy w Gatlingu?",

  "workshop.description.label": "Opis warsztatu",
  "workshop.what.you.will.learn": "Czego się nauczysz",
  "workshop.practical.knowledge.label": "Praktyczna wiedza",
  "workshop.when.label": "Kiedy?",
  "workshop.how.to.join.label": "Jak dołączyć?",
  "workshop.how.it.looks.label": "Format warsztatu",
  "workshop.trainer.about": "O trenerze",
  "workshop.coming.soon": "Już wkrótce...",
  "workshop.see.more": "Szczegóły",
  "workshop.sign.up.now": "Zapisz się teraz",
  "workshop.buy.ticket": "Kup Bilet",
  "workshop.tickets": "Bilety",
  "workshop.videos": "Wideo",
  "workshop.resources": "Inne materiały",

  "menu.agenda": "Agenda",
  "menu.videos": "Video",
  "menu.trainer": "Trener",
  "menu.tickets": "Bilety",
  "menu.other.workshops": "Inne warsztaty",

  "trainer.andrzej.description": "Architekt Oprogramowania (Scala/Java), Konsultant, Prelegent i Trener.\nCertyfikacje: Reactive Architect, Cassandra Architect, Kafka Developer",

  "gatling.title": "Testy wydajnościowe z Gatlingiem",
  "gatling.subtitle": "Zdobądź praktyczną wiedzę z zakresu testowania wydajności aplikacji webowych. Poznaj najczęstsze błędy mierzenia wydajności i jak ich uniknąć.",
  "gatling.workshop.description1": "<p>Warsztaty skupiają się głównie na praktycznym podejściu do testowania wydajności - pracujemy z realną aplikacją webową, którą należy przetestować pod kątem wydajności i przeanalizować gdzie są problemy z jej wydajnością. Między praktycznymi zadaniami poruszamy teoretyczne podstawy testowania wydajności oraz pokazujemy najczęstsze błędy oraz jak ich uniknąć.</p><p>Gatling wraz z Apache JMeter i Locust jest jednym z najbardziej popularnych narzędzi do testowania wydajności. Umożliwia tworzenie bardzo czytelnych, reużywalnych i komponowalnych testów wydajności, a niektóre z funkcji nie są możliwe do osiągnięcia w innych narzędziach. </p>",
  "gatling.workshop.description2": "<p>Wymaga dużo mniej zasobów do wygenerowania oczekiwane obciążenia, a dodatkowo umożliwia rozproszone testowanie z wielu maszyn jednocześnie, co jest szczególnie istotne w przypadku systemów opartych na rozwiązaniach chmurowych</p><p>Gatling bazuje na języku <b>Scala</b>, aczkolwiek umiejętność posługiwania się tym językiem nie jest kluczowa żeby uczestniczyć w szkoleniu. Dobra znajomość dowolnego języka oprogramowania jest wystarczająca.",
  "gatling.practical.knowledge": "Masz dość kilkutygodniowych kursów video, po których dużo wiesz, ale mało umiesz? Nasze szkolenie jest maksymalnie zorientowane na ćwiczenia praktyczne z realną aplikacją internetową, którą należy pokryć testami oraz wykryć problemy z wydajnością. Duży nacisk kładziony jest również na umiejętność analizy wyników takich testów, co jest kluczowe z perspektywy biznesu, a bardzo często jest pomijane w podobnych szkoleniach.",
  "gatling.why.label": "Dlaczego Gatling?",
  "gatling.why.content": "Gatling darmowe narzędzie do testów automatycznych i wydajnościowych. Pozwala on na tworzenie bardzo czytelnych, łatwych w utrzymaniu i komponowalnych testów. Oprócz standardowych testów protokołu HTTP możemy używać Gatlinga do testów WebSocketów, JMS oraz innych systemów, ponieważ architektura tego narzędzia jest bardzo łatwo rozszerzalna. Oprócz tego umożliwia testowanie rozproszone z wielu maszyn jednocześnie.",

  "gatling.when": "Kolejny termin szkolenia już wkrótce. Dołącz do listy mailingowej, by dowiedzieć się pierwszy o terminie i szczegółach.",
  "gatling.how.to.join.content": "Chcesz poznać tajniki Gatlinga? Napisz na <a href=\"mailto:{email}\">{email}</a> a dogramy szczegóły i stworzymy dedykowany plan warsztatu dla Ciebie i Twojego zespołu.",
  "gatling.how.it.looks": "Szkolenie trwa 2 dni. W wersji on-line spotykamy się na Zoomie a do komunikacji tekstowej wykorzystujemy Slacka.",
  "gatling.why.in.scala.label": "Scala?",
  "gatling.why.in.scala.content": "Gatling bazuje na języku Scala, ale znajomość dowolnego języka oprogramowania jest wystarczająca żeby uczestniczyć w szkoleniu. Przed szkoleniem uczestnicy dostają dodatkowe materiały pozwalające na szybkie poznanie składki tego języka.",
  "gatling.trainer.label": "Trener: {name}",
  "gatling.agenda.chapter1.title": "Teoretyczne podstawy testów wydajności",
  "gatling.agenda.chapter1.content.item1": "przygotowanie środowiska, monitoring, logowanie, profiling",
  "gatling.agenda.chapter1.content.item2": "metryki, czyli jak można porównać wyniki testów wydajności i nie nie dać oszukać się statystyce",
  "gatling.agenda.chapter1.content.item3": "błędy w popularnych narzędziach do testów wydajności, np. Coordinated Omission problem",
  "gatling.agenda.chapter2.title": "Podstawy działania sbt i projektów Scali",
  "gatling.agenda.chapter2.content.item1": "instalacja i konfiguracja podstawowych narzędzi",
  "gatling.agenda.chapter2.content.item2": "tworzenie nowego projektu z Gatlingiem",
  "gatling.agenda.chapter2.content.item3": "import projektu do IDE (IntelliJ IDEA)",
  "gatling.agenda.chapter2.content.item4": "podstawowe komendy sbt do pracy z Gatlingiem i Scalą",
  "gatling.agenda.chapter3.title": "Podstawy Gatlinga",
  "gatling.agenda.chapter3.content.item1": "architektura",
  "gatling.agenda.chapter3.content.item2": "porównanie z innymi narzędziami (JMeter, Selenium)",
  "gatling.agenda.chapter3.content.item3": "utworzenie i uruchomienie pierwszego scenariusza testowego",
  "gatling.agenda.chapter4.title": "Automatyczne generowanie testów na podstawie ruchu HTTP",
  "gatling.agenda.chapter4.content.item1": "konfiguracja i podłączenie Gatling Recordera",
  "gatling.agenda.chapter5.title": "Zaawansowane funkcje Gatlinga",
  "gatling.agenda.chapter5.content.item1": "walidacje odpowiedzi",
  "gatling.agenda.chapter5.content.item2": "pętle, wyrażenie warunkowe, losowość",
  "gatling.agenda.chapter5.content.item3": "wirtualna sesja użytkownika",
  "gatling.agenda.chapter5.content.item4": "obsługa błędów w odpowiedziach",
  "gatling.agenda.chapter6.title": "Jakość testów",
  "gatling.agenda.chapter6.content.item1": "poprawa reużywalności, kompozycji testów",
  "gatling.agenda.chapter6.content.item2": "zapewnienie utrzymania testów i gotowość na zmiany",
  "gatling.agenda.chapter7.title": "Symulacja ruchu produkcyjnego",
  "gatling.agenda.chapter7.content.item1": "generowanie danych testowych",
  "gatling.agenda.chapter7.content.item2": "zaawansowane sterowanie ruchem generowanym przez Gatlinga",
  "gatling.agenda.chapter8.title": "Wyniki testów wydajności",
  "gatling.agenda.chapter8.content.item1": "generowanie raportów HTML z wynikami",
  "gatling.agenda.chapter8.content.item2": "globalne i jednostkowe metryki oraz wykresy",
  "gatling.agenda.chapter8.content.item3": "throughput a latency",
  "gatling.agenda.chapter9.title": "Testy rozproszone",
  "gatling.agenda.chapter9.content.item1": "generowanie maksymalnego obciążenia z wielu maszyn jednocześnie z użyciem Gatlinga",
  "gatling.agenda.chapter9.content.item2": "zbierania i przetwarzanie wyników",
  "gatling.agenda.chapter9.content.item3": "alternatywne narzędzia automatyzacji testów rozproszonych",
  "gatling.agenda.chapter10.title": "Testy asynchroniczne (opcjonalnie)",
  "gatling.agenda.chapter10.content.item1": "problemy z testami asynchronicznego przepływu",
  "gatling.agenda.chapter10.content.item2": "testy WebSocketów",
  "gatling.agenda.chapter10.content.item3": "testy JMS",

  "akka.typed.title": "Podstawy Akka Typed (Java/Scala)",
  "akka.typed.subtitle": "Jeden z głównych problemów z Akki, czyli brak typowanych aktorów, został w końcu rozwiązany. Od wersji 2.6.0 Akka Typed jest już dojrzałym projektem, zalecanym jako domyślny wybór.",

  "akka.typed.workshop.description1": "<p>Jeden z głównym problemów z Akki, czyli brak typowanych aktorów, został w końcu rozwiązany. Od wersji 2.6.0 Akka Typed jest już dojrzałym projektem, zalecanym jako domyślny wybór przy pracy z różnymi bibliotekami stosu Akki. Przeanalizujmy wspólnie najważniejsze wzorce i najlepsze praktyki, przy wdrażaniu typowanych aktorów do naszego systemu.</p>",
  "akka.typed.workshop.description2": "<p>Gdy Akka osiągnęła szerokie zastosowanie w systemach produkcyjnych, jej użytkownicy zidentyfikowali kilka problemów, które są dość problematyczne i często powodują nietypowe błędy. Przećwiczmy, jak wykorzystać nowy model nadzoru, ulepszoną obsługę cyklu życia aktorów, ulepszone timery i wiele innych usprawnień. Podczas tego warsztatu poznasz ważne wzorce i wnioski, które zostały przez nas wybrane podczas pracy nad realnymi aplikacjami produkcyjnymi.</p>",
  "akka.typed.practical.knowledge.content": "Szkolenie jest zorientowane na ćwiczenia praktyczne wykorzystujące bibliotekę Akka Actor Typed. Kodujemy w nowym API aktorów. Poznajemy podstawy zachowań, cykl życia aktora, nadzór i inne podstawowe zagadnienia związane z aktorami. Warsztaty mogą być prowadzone dla programistów Java lub Scala.",
  "akka.typed.why.label": "Dlaczego Akka Typed?",
  "akka.typed.why.content": "Stos bibliotek Akki to jedna z najpopularniejszych platform reaktywnych wykorzystywanych obecnie na rynku. Akka jest pomyślnie wdrażana w wielu rozwiązaniach produkcyjnych, gdzie skalowanie i szybkość działania grają kluczową rolę. Aktorzy są podstawowym modułem, na bazie którego zbudowane są wszystkie inne biblioteki, takie jak Akka Cluster, Sharding, Persistence, Streams itp.",
  "akka.typed.when.label": "Kiedy?",
  "akka.typed.when.content": "Kolejny termin szkolenia już wkrótce. Dołącz do listy mailingowej, by dowiedzieć się pierwszy o terminie i szczegółach.",
  "akka.typed.how.to.join.content": "Chcesz poznać typowaną Akkę? Napisz na <a href=\"mailto:{email}\">{email}</a> a dogramy szczegóły i stworzymy dedykowany plan warsztatu dla Ciebie i Twojego zespołu.",
  "akka.typed.how.it.looks": "Szkolenie trwa 1-2 dni. W wersji on-line spotykamy się na Zoomie a do komunikacji tekstowej wykorzystujemy Slacka.",
  "akka.typed.next.item1.label": "Wymagania",
  "akka.typed.next.item1.content": "Podstawowa znajomość języka Java lub Scala. Nie jest wymagana żadna wcześniejsza znajomość stosu Akki, chociaż użytkownicy doświadczeni w klasycznej Akce również nauczą się czegoś nowego.",
  "akka.typed.next.item2.label": "",
  "akka.typed.next.item2.content": "",

  "akka.typed.agenda.chapter1": "Model Aktorów - podstawowa teoria",
  "akka.typed.agenda.chapter2": "Wady nietypowanych aktorów",
  "akka.typed.agenda.chapter3": "Tworzenie aktorów",
  "akka.typed.agenda.chapter4": "sender() vs replyTo",
  "akka.typed.agenda.chapter5": "Zachowania aktora",
  "akka.typed.agenda.chapter6": "Testowanie",
  "akka.typed.agenda.chapter7": "Cykl życia typowanych aktorów",
  "akka.typed.agenda.chapter8": "Projektowanie API aktora",
  "akka.typed.agenda.chapter9": "Obsługa błędów i nadzór aktorów",
  "akka.typed.agenda.chapter10": "Tworzenie i zarządzanie dziećmi aktorów",
  "akka.typed.agenda.chapter11": "Timers",
  "akka.typed.agenda.chapter12": "Wzorzec recepcjonisty",
  "akka.typed.agenda.chapter13": "Koegzystencja aktorów typowanych i nietypowanych (opcjonalnie)",
  "akka.typed.agenda.chapter14": "Stash (opcjonalnie)",
  "akka.typed.agenda.chapter15": "Dispatchers (opcjonalnie)",

  "event.sourcing.title": "Reactive Event Sourcing w Javie",
  "event.sourcing.subtitle": "Zastanawiasz się, czy Event Sourcing naprawdę rozwiąże Twoje problemy? Jak zacząć budować rozwiązania w oparciu o ES? Jak uniknąć klasycznych błędów? Ten warsztat odpowie na te wszystkie pytania oraz nauczy cię budowania systemów opartych na Event Sourcingu.",

  "event.sourcing.workshop.description1": "<p>Event Sourcing to jeden z ciekawszych wzorców w projektowaniu aplikacji. Wdrożenie Event Sourcingu wpływa na różne poziomy systemu, dlatego wzorzec ten jest ściśle powiązany z architekturą całego systemu.</p><p>Jeśli jesteś ciekawy, czy Event Sourcing naprawdę rozwiąże Twoje problemy. Jak zacząć budować rozwiązania w oparciu o Event Sourcing, jak ulepszyć istniejące rozwiązanie za pomocą Event Sourcingu lub jak uniknąć klasycznych błędów? Ten warsztat zdecydowanie powinien przykuć Twoją uwagę.</p>",
  "event.sourcing.workshop.description2": "<p>Szkolenie koncentruje się nie tylko na samym Event Sourcingu, ale także na wielu innych aspektach budowy reaktywnych systemów rozproszonych. Część praktyczną można przeprowadzić w Scali lub Javie w zależności od potrzeb grupy.</p>",
  "event.sourcing.practical.knowledge.content": "Praktyczny warsztat z reaktywnego Event Sourcing opraty na Akce, który pokazuje gotowe do użycia rozwiązania produkcyjne i podstawy teorii związanej z Event Sourcingiem. Warsztat zorientowany jest na pracę z kodem, gdzie budujemy prostą aplikację bazująca na Event Sourcingu. Ćwiczenia nie są trywialne i próbują symulować realne przypadki użycia i problemy produkcyjne.",
  "event.sourcing.why.label": "Reaktywny stos technologiczny",
  "event.sourcing.why.content": "Warsztat z Reactive Event Sourcingu oparty jest na zaawansowanym i nowoczesnym stosie technologicznym, głównie: Akka Persistence, Akka Sharding, Akka Cluster, Akka Streams. Nie chodzi tylko o stos, ale także o zmianę sposobu myślenia w kierunku przetwarzania zdarzeń i projektowaniu rozwiązaniach zorientowanych na zdarzeniach.",
  "event.sourcing.when.label": "Kiedy?",
  "event.sourcing.when.content": "Kolejny termin szkolenia już wkrótce. Dołącz do listy mailingowej, by dowiedzieć się pierwszy o terminie i szczegółach.",
  "event.sourcing.how.to.join.content": "Chcesz poznać Event Sourcing oparty na stosie Akkowym? Napisz na <a href=\"mailto:{email}\">{email}</a> a dogramy szczegóły i stworzymy dedykowany plan warsztatu dla Ciebie i Twojego zespołu.",
  "event.sourcing.how.it.looks": "Szkolenie trwa 2-3 dni, w zależności od wybranych zaganień. W wersji on-line spotykamy się na Zoomie a do komunikacji tekstowej wykorzystujemy Slacka.",
  "event.sourcing.next.item1.label": "Wymagania",
  "event.sourcing.next.item1.content": "Podstawowa znajomość języka Java lub Scala. Nie jest wymagana żadna wcześniejsza znajomość stosu Akki.",
  "event.sourcing.next.item2.label": "",
  "event.sourcing.next.item2.content": "",

  "event.sourcing.agenda.chapter1": "Dlaczego reaktywność",
  "event.sourcing.agenda.chapter2": "Reaktywne mikroserwisy",
  "event.sourcing.agenda.chapter2.item1": "izolacja stanu, obszaru, czasu i błędów",
  "event.sourcing.agenda.chapter2.item2": "reaktywne wzorce wykorzystywane w mikroserwisach",
  "event.sourcing.agenda.chapter3": "Spójność, dostępność, skalowalność",
  "event.sourcing.agenda.chapter3.item1": "wydajność vs skalowalność",
  "event.sourcing.agenda.chapter3.item2": "natychmiastowa spójność vs ewentualna spójność",
  "event.sourcing.agenda.chapter3.item3": "contention i prawo Amdahla",
  "event.sourcing.agenda.chapter3.item4": "coherency i prawo Gunthera",
  "event.sourcing.agenda.chapter3.item5": "sharding",
  "event.sourcing.agenda.chapter3.item6": "CRDT",
  "event.sourcing.agenda.chapter4": "Przesyłanie wiadomość i koordynacja",
  "event.sourcing.agenda.chapter4.item1": "synchroniczne vs asynchronicznie przesyłanie wiadomości",
  "event.sourcing.agenda.chapter4.item2": "gwarancje dostarczenia",
  "event.sourcing.agenda.chapter5": "Model aktorów",
  "event.sourcing.agenda.chapter5.item1": "podsumowanie stosu Akki",
  "event.sourcing.agenda.chapter5.item2": "podstawy aktorów",
  "event.sourcing.agenda.chapter5.item3": "Actor System",
  "event.sourcing.agenda.chapter5.item4": "testowanie aktorów",
  "event.sourcing.agenda.chapter5.item5": "cykl życia aktorów",
  "event.sourcing.agenda.chapter5.item6": "obsługa błędów w aktorach",
  "event.sourcing.agenda.chapter6": "Event Sourcing",
  "event.sourcing.agenda.chapter6.item1": "wady i zalety message driven vs event driven & event first approach",
  "event.sourcing.agenda.chapter6.item2": "CQRS",
  "event.sourcing.agenda.chapter6.item3": "różne sposoby implementacji Event Sourcingu",
  "event.sourcing.agenda.chapter6.item4": "Command Sourcing",
  "event.sourcing.agenda.chapter6.item5": "Event Collaboration",
  "event.sourcing.agenda.chapter7": "Wyzwania przy implementacji rozproszonych systemów opartych na Event Sourcingu",
  "event.sourcing.agenda.chapter7.item1": "spójność danych",
  "event.sourcing.agenda.chapter7.item2": "zasada pojedynczego pisarza",
  "event.sourcing.agenda.chapter8": "Akka Persistence",
  "event.sourcing.agenda.chapter8.item1": "podstawowe koncepcje",
  "event.sourcing.agenda.chapter8.item2": "architektura",
  "event.sourcing.agenda.chapter8.item3": "separacja domeny",
  "event.sourcing.agenda.chapter9": "Implementacja agregatu domenowego",
  "event.sourcing.agenda.chapter9.item1": "podstawowy przepływ domenowy: komenda -> agregat -> zdarzenia",
  "event.sourcing.agenda.chapter9.item2": "podstawowy przepływ aplikacyjny: serwis -> aktor persystentny -> agregat -> baza danych",
  "event.sourcing.agenda.chapter9.item3": "stan aktora persystentnego",
  "event.sourcing.agenda.chapter9.item4": "API domeny",
  "event.sourcing.agenda.chapter9.item5": "API aktora persystentnego",
  "event.sourcing.agenda.chapter10": "Deduplikacja",
  "event.sourcing.agenda.chapter10.item1": "at-most-once",
  "event.sourcing.agenda.chapter10.item2": "exactly-once",
  "event.sourcing.agenda.chapter11": "Ścieżka odczytu w Event Sourcingu, odpytywanie o stan",
  "event.sourcing.agenda.chapter12": "Cykl odbudowywania stanu akora",
  "event.sourcing.agenda.chapter13": "Walidacja domenowa",
  "event.sourcing.agenda.chapter14": "Wzbogacanie zdarzeń",
  "event.sourcing.agenda.chapter15": "Projekcje",
  "event.sourcing.agenda.chapter15.item1": "konfiguracja Akka Persistent Query",
  "event.sourcing.agenda.chapter15.item2": "implementacja Command Query Responsibility Segregation na bazie Event Sourcingu",
  "event.sourcing.agenda.chapter15.item3": "reaktywne projekcje",
  "event.sourcing.agenda.chapter16": "Serializacja",
  "event.sourcing.agenda.chapter16.item1": "strategie serializacyjne",
  "event.sourcing.agenda.chapter16.item2": "ewolucja schematu: kompatybilność wstecz i wprzód",
  "event.sourcing.agenda.chapter17": "Akka Cluster",
  "event.sourcing.agenda.chapter17.item1": "podstawowa konfiguracja",
  "event.sourcing.agenda.chapter17.item2": "split brain",
  "event.sourcing.agenda.chapter17.item3": "strategie deployowania",
  "event.sourcing.agenda.chapter18": "Akka Persistence Cassandra",
  "event.sourcing.agenda.chapter18.item1": "alternatywnie, zamiast Cassandry może być SQL, DynamoDB, Couchbase",
  "event.sourcing.agenda.chapter18.item2": "ewentualnie spójność",
  "event.sourcing.agenda.chapter19": "Transakcje między wieloma agregatami a wzorzec Sagi",
  "event.sourcing.agenda.chapter19.item1": "choreografia",
  "event.sourcing.agenda.chapter19.item2": "orkiestracja",

  "akka.cluster.title": "Akka Cluster",
  "akka.cluster.subtitle": "Dowiedz się jak działa technologia Akka Cluster. Przeprowadzimy cię przez praktyczne ćwiczenia pokazujące najbardziej istotne funkcjonalności.",

  "akka.cluster.workshop.description1": "<p>Podczas pracy nad systemami rozproszonymi prędzej czy później zderzysz się z problemem skalowania aplikacji. W większości tego typu rozwiązań skalowanie odbywa się poprzez uruchomienie kolejnej instancji. Niestety, nie zawsze takie podejście przynosi wymierne korzyści, bo bardzo szybko wąskim gardłem staje się np. baza danych. Owszem sama baza danych też może być skalowana, i/lub shardowana, ale to również nie rozwiązuje wszystkich problemów. A na pewno tworzy nowe wyzwania, jak chociażby spójność danych.</p>",
  "akka.cluster.workshop.description2": "<p>Wtedy warto rozważyć użycie technologii Akka Cluster, która zapewnia odporną na błędy zdecentralizowaną usługę Cluster Membership, opartą na sieci peer-to-peer, bez pojedynczego punktu awarii lub pojedynczego wąskiego gardła. Co to oznacza w praktyce? Bazując na tej technologii, możesz tworzyć systemy rozproszone, które są dynamicznie skalowane w momencie gdy zależy ci na wysokiej dostępności (Akka CRDTs), jak również na spójności danych (Akka Persistence + Sharding).</p>",
  "akka.cluster.practical.knowledge.content": "Ten warsztat umożliwi Ci naukę technologi Akka Cluster przez zestaw praktycznych ćwiczeń pokrywających takie tematy jak: startowanie, zarządzanie, rozdzielenie, naprawianie klastra. Oprócz poznania wymaganej teorii potrzebnej do zrozumienia działających pod spodem mechanizmów, dowiesz się również jak skonfigurować i monitorować klaster na środowisku produkcyjnym.\n",
  "akka.cluster.why.label": "Dlaczego Akka Cluster?",
  "akka.cluster.why.content": "Akka Cluster jest świetną biblioteką, która rozwiązuje specyficzne problemy systemów rozproszonych w rewelacyjny sposób. Podczas warsztatów przedyskutujemy nie tylko przypadki użycia, gdzie ta technologia jest idealnym wyborem, ale również skupimy się na sytuacjach, gdzie Akka Cluster nie powinien być używany. Ta druga część jest nawet bardziej istotna, ponieważ z naszego doświadczenia programiści mają tendencje do nadużywania tej technologii i stosowania jej w rozwiązaniach, które tego nie wymagają i nie będą czerpać zysków z jej użycia.",
  "akka.cluster.when.label": "Kiedy?",
  "akka.cluster.when.content": "Kolejny termin szkolenia już wkrótce. Dołącz do listy mailingowej, by dowiedzieć się pierwszy o terminie i szczegółach.",
  "akka.cluster.how.to.join.content": "Chcesz dowiedzieć się więcej na temat szkolenia z Akka Cluster? Napisz na <a href=\"mailto:{email}\">{email}</a> a dogramy szczegóły i stworzymy dedykowany plan warsztatu dla Ciebie i Twojego zespołu.",
  "akka.cluster.how.it.looks": "Szkolenie trwa 1 dzień. W wersji on-line spotykamy się na Zoomie a do komunikacji tekstowej wykorzystujemy Slacka.",
  "akka.cluster.next.item1.label": "Wymagania",
  "akka.cluster.next.item1.content": "Nie jest wymagana żadna wcześniejsza znajomość stosu Akki.",
  "akka.cluster.next.item2.label": "",
  "akka.cluster.next.item2.content": "",

  "akka.cluster.agenda.chapter1": "Stateful vs stateless systems",
  "akka.cluster.agenda.chapter2": "Cluster bootstrap",
  "akka.cluster.agenda.chapter3": "Cluster sharding",
  "akka.cluster.agenda.chapter3.item1": "sharding entities",
  "akka.cluster.agenda.chapter3.item2": "sharding event sourced entities",
  "akka.cluster.agenda.chapter3.item3": "rebalancing",
  "akka.cluster.agenda.chapter3.item4": "passivation",
  "akka.cluster.agenda.chapter3.item5": "remember entities",
  "akka.cluster.agenda.chapter4": "Cluster management",
  "akka.cluster.agenda.chapter5": "Cluster failures",
  "akka.cluster.agenda.chapter5.item1": "healing strategies",
  "akka.cluster.agenda.chapter5.item2": "split brain",
  "akka.cluster.agenda.chapter6": "Cluster singleton",
  "akka.cluster.agenda.chapter7": "Distributed data (CRDTs)",
}
