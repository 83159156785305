import * as React from 'react';
import ReactPlayer from "react-player";
import { useEffect, useState } from "react";

type Props = {
  link: string;
  size?: number;
  onEnded?(): void;
}

const VideoPlayer: React.FC<Props> = ({ link, size, onEnded }) => {
  const [playerSize, setPlayerSize] = useState(800);

  useEffect(() => {
      setPlayerSize(size || 800);
  }, []);

  return (
    <div>
      <ReactPlayer url={`${link}`} controls={true} height={playerSize} width="100%" onEnded={onEnded}/>
    </div>
  )
};

export default VideoPlayer;
