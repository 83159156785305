import React, { useCallback, useState } from 'react';
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { changePassword } from "../UserService/UserService";
import TextField from "../common/TextField";

type FormValues = {
  currentPassword: string;
  newPassword: string;
  repeatedNewPassword: string;
}

type Props = {
  notifyError: (msg: string) => void;
  notifySuccess: (msg: string) => void;
}

const validationSchema = Yup.object<FormValues>({
  currentPassword: Yup.string()
    .required('Current password required'),
  newPassword: Yup.string()
    .required('New password Required')
    .min(5, "New password must be at least 5 characters long"),
  repeatedNewPassword: Yup.string()
    .required('Repeated password required')
    .when("newPassword", {
      is: (value: string) => !!(value && value.length > 0),
      then: Yup.string().oneOf(
        [Yup.ref("newPassword")],
        "Passwords don't match!"
      )
    }),
});

const initialValues: FormValues = {
  currentPassword: '',
  newPassword: '',
  repeatedNewPassword: ''
};

const PasswordDetails: React.FC<Props> = (props) => {
  const { notifyError, notifySuccess } = props;
  const [isSubmitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(async ({ currentPassword, newPassword }) => {
    const changePasswordResult = await changePassword({ currentPassword, newPassword });

    changePasswordResult.fold({
      left: () => {
        notifyError('Could not change password!');
      },
      right: () => {
        notifySuccess('Password changed!');
      }
    });
  }, []);

  return (
    <div className="PasswordDetails">
      <h4>Password details</h4>
      <Formik initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}>
        <Form className="common-form">
          <TextField type="password" name="currentPassword" placeholder="Current password" className="form-control"/>
          <TextField type="password" name="newPassword" placeholder="New password" className="form-control"/>
          <TextField type="password" name="repeatedNewPassword" placeholder="Repeated new password"
                     className="form-control"/>
          <input type="submit" value="update password" className="btn btn-primary" disabled={isSubmitting}/>
        </Form>
      </Formik>
    </div>
  );
};

export default PasswordDetails;
