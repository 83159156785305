import * as React from 'react'
import SimpleRegister from "./SimpleRegister";


type Props = {
  onRegisterSuccess: (apiKey: string) => void;
  notifyError: (msg: string) => void;
}

const RegisterAndRedeem: React.FC<Props> = props => {

  const handleFailure = () => {
    props.notifyError("Could not register new user!");
  };

  return <>
    <p className="font-weight-bold text-monospace">Create new account and assign course to it</p>
    <SimpleRegister onRegisterSuccess={props.onRegisterSuccess} onRegisterFailure={handleFailure}
                    formClassNames={["common-form m-auto"]}/>
  </>
};

export default RegisterAndRedeem
