import React from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { WorkshopDescriptionData } from "../types";

type WorkshopDescriptionTileProps = {
  cardTitleId?: string;
  cardContentId: string;
  titleData?: any;
  contentData?: any;
};
const WorkshopDescriptionTile: React.FC<WorkshopDescriptionTileProps> =
  ({ cardTitleId, cardContentId, titleData, contentData }) => {
    return (
      <div className="description-column">
        <div className="card">
          {cardTitleId && <div className="card-title">
            <FormattedMessage id={cardTitleId} values={titleData}/>
          </div>}
          <div className="card-content">
            <FormattedHTMLMessage id={cardContentId} values={contentData}/>
          </div>
        </div>
      </div>
    )
  };
const WorkshopDescription: React.FC<{ data: WorkshopDescriptionData }> = (props) => {
  const {
    description1Id, description2Id, practicalKnowledge,
    howItLooks, howJoin, otherLabel, otherContent, when,
    whyLabel, whyContent
  } = props.data;

  return (
    <div className="workshop-description">
      <h1>
        <FormattedMessage id="workshop.description.label"/>
      </h1>
      <div className="description-row">
        <WorkshopDescriptionTile cardContentId={description1Id}/>
        <WorkshopDescriptionTile cardContentId={description2Id}/>
      </div>
      <div className="description-row">
        <WorkshopDescriptionTile cardTitleId="workshop.practical.knowledge.label"
                                 cardContentId={practicalKnowledge}/>
        <WorkshopDescriptionTile cardTitleId={whyLabel}
                                 cardContentId={whyContent}/>
      </div>
      <div className="description-row">
        <WorkshopDescriptionTile cardTitleId="workshop.when.label"
                                 cardContentId={when}/>
        <WorkshopDescriptionTile cardTitleId="workshop.how.to.join.label"
                                 cardContentId={howJoin}
                                 contentData={{ email: "academy@softwaremill.com" }}/>
      </div>
      <div className="description-row">
        <WorkshopDescriptionTile cardTitleId="workshop.how.it.looks.label"
                                 cardContentId={howItLooks}/>
        <WorkshopDescriptionTile cardTitleId={otherLabel}
                                 cardContentId={otherContent}/>
      </div>
    </div>
  )
};

export default WorkshopDescription;
