type CountryCode = {
  isoCode: string,
  countryName: string
}
const CountryCodes: CountryCode[] = [
  {isoCode: "AF", countryName: "AFGHANISTAN"},
  {isoCode: "AL", countryName: "ALBANIA"},
  {isoCode: "DZ", countryName: "ALGERIA"},
  {isoCode: "AS", countryName: "AMERICAN SAMOA"},
  {isoCode: "AD", countryName: "ANDORRA"},
  {isoCode: "AO", countryName: "ANGOLA"},
  {isoCode: "AI", countryName: "ANGUILLA"},
  {isoCode: "AQ", countryName: "ANTARCTICA"},
  {isoCode: "AG", countryName: "ANTIGUA AND BARBUDA"},
  {isoCode: "AR", countryName: "ARGENTINA"},
  {isoCode: "AM", countryName: "ARMENIA"},
  {isoCode: "AW", countryName: "ARUBA"},
  {isoCode: "AU", countryName: "AUSTRALIA"},
  {isoCode: "AT", countryName: "AUSTRIA"},
  {isoCode: "AZ", countryName: "AZERBAIJAN"},
  {isoCode: "BS", countryName: "BAHAMAS (THE)"},
  {isoCode: "BH", countryName: "BAHRAIN"},
  {isoCode: "BD", countryName: "BANGLADESH"},
  {isoCode: "BB", countryName: "BARBADOS"},
  {isoCode: "BY", countryName: "BELARUS"},
  {isoCode: "BE", countryName: "BELGIUM"},
  {isoCode: "BZ", countryName: "BELIZE"},
  {isoCode: "BJ", countryName: "BENIN"},
  {isoCode: "BM", countryName: "BERMUDA"},
  {isoCode: "BT", countryName: "BHUTAN"},
  {isoCode: "BO", countryName: "BOLIVIA (PLURINATIONAL STATE OF)"},
  {isoCode: "BQ", countryName: "BONAIRE, SINT EUSTATIUS AND SABA"},
  {isoCode: "BA", countryName: "BOSNIA AND HERZEGOVINA"},
  {isoCode: "BW", countryName: "BOTSWANA"},
  {isoCode: "BV", countryName: "BOUVET ISLAND"},
  {isoCode: "BR", countryName: "BRAZIL"},
  {isoCode: "IO", countryName: "BRITISH INDIAN OCEAN TERRITORY (THE)"},
  {isoCode: "BN", countryName: "BRUNEI DARUSSALAM"},
  {isoCode: "BG", countryName: "BULGARIA"},
  {isoCode: "BF", countryName: "BURKINA FASO"},
  {isoCode: "BI", countryName: "BURUNDI"},
  {isoCode: "CV", countryName: "CABO VERDE"},
  {isoCode: "KH", countryName: "CAMBODIA"},
  {isoCode: "CM", countryName: "CAMEROON"},
  {isoCode: "CA", countryName: "CANADA"},
  {isoCode: "KY", countryName: "CAYMAN ISLANDS (THE)"},
  {isoCode: "CF", countryName: "CENTRAL AFRICAN REPUBLIC (THE)"},
  {isoCode: "TD", countryName: "CHAD"},
  {isoCode: "CL", countryName: "CHILE"},
  {isoCode: "CN", countryName: "CHINA"},
  {isoCode: "CX", countryName: "CHRISTMAS ISLAND"},
  {isoCode: "CC", countryName: "COCOS (KEELING) ISLANDS (THE)"},
  {isoCode: "CO", countryName: "COLOMBIA"},
  {isoCode: "KM", countryName: "COMOROS (THE)"},
  {isoCode: "CD", countryName: "CONGO (THE DEMOCRATIC REPUBLIC OF THE)"},
  {isoCode: "CG", countryName: "CONGO (THE)"},
  {isoCode: "CK", countryName: "COOK ISLANDS (THE)"},
  {isoCode: "CR", countryName: "COSTA RICA"},
  {isoCode: "HR", countryName: "CROATIA"},
  {isoCode: "CU", countryName: "CUBA"},
  {isoCode: "CW", countryName: "CURAÇAO"},
  {isoCode: "CY", countryName: "CYPRUS"},
  {isoCode: "CZ", countryName: "CZECHIA"},
  {isoCode: "CI", countryName: "CÔTE D'IVOIRE"},
  {isoCode: "DK", countryName: "DENMARK"},
  {isoCode: "DJ", countryName: "DJIBOUTI"},
  {isoCode: "DM", countryName: "DOMINICA"},
  {isoCode: "DO", countryName: "DOMINICAN REPUBLIC (THE)"},
  {isoCode: "EC", countryName: "ECUADOR"},
  {isoCode: "EG", countryName: "EGYPT"},
  {isoCode: "SV", countryName: "EL SALVADOR"},
  {isoCode: "GQ", countryName: "EQUATORIAL GUINEA"},
  {isoCode: "ER", countryName: "ERITREA"},
  {isoCode: "EE", countryName: "ESTONIA"},
  {isoCode: "SZ", countryName: "ESWATINI"},
  {isoCode: "ET", countryName: "ETHIOPIA"},
  {isoCode: "FK", countryName: "FALKLAND ISLANDS (THE) [MALVINAS]"},
  {isoCode: "FO", countryName: "FAROE ISLANDS (THE)"},
  {isoCode: "FJ", countryName: "FIJI"},
  {isoCode: "FI", countryName: "FINLAND"},
  {isoCode: "FR", countryName: "FRANCE"},
  {isoCode: "GF", countryName: "FRENCH GUIANA"},
  {isoCode: "PF", countryName: "FRENCH POLYNESIA"},
  {isoCode: "TF", countryName: "FRENCH SOUTHERN TERRITORIES (THE)"},
  {isoCode: "GA", countryName: "GABON"},
  {isoCode: "GM", countryName: "GAMBIA (THE)"},
  {isoCode: "GE", countryName: "GEORGIA"},
  {isoCode: "DE", countryName: "GERMANY"},
  {isoCode: "GH", countryName: "GHANA"},
  {isoCode: "GI", countryName: "GIBRALTAR"},
  {isoCode: "GR", countryName: "GREECE"},
  {isoCode: "GL", countryName: "GREENLAND"},
  {isoCode: "GD", countryName: "GRENADA"},
  {isoCode: "GP", countryName: "GUADELOUPE"},
  {isoCode: "GU", countryName: "GUAM"},
  {isoCode: "GT", countryName: "GUATEMALA"},
  {isoCode: "GG", countryName: "GUERNSEY"},
  {isoCode: "GN", countryName: "GUINEA"},
  {isoCode: "GW", countryName: "GUINEA-BISSAU"},
  {isoCode: "GY", countryName: "GUYANA"},
  {isoCode: "HT", countryName: "HAITI"},
  {isoCode: "HM", countryName: "HEARD ISLAND AND MCDONALD ISLANDS"},
  {isoCode: "VA", countryName: "HOLY SEE (THE)"},
  {isoCode: "HN", countryName: "HONDURAS"},
  {isoCode: "HK", countryName: "HONG KONG"},
  {isoCode: "HU", countryName: "HUNGARY"},
  {isoCode: "IS", countryName: "ICELAND"},
  {isoCode: "IN", countryName: "INDIA"},
  {isoCode: "ID", countryName: "INDONESIA"},
  {isoCode: "IR", countryName: "IRAN (ISLAMIC REPUBLIC OF)"},
  {isoCode: "IQ", countryName: "IRAQ"},
  {isoCode: "IE", countryName: "IRELAND"},
  {isoCode: "IM", countryName: "ISLE OF MAN"},
  {isoCode: "IL", countryName: "ISRAEL"},
  {isoCode: "IT", countryName: "ITALY"},
  {isoCode: "JM", countryName: "JAMAICA"},
  {isoCode: "JP", countryName: "JAPAN"},
  {isoCode: "JE", countryName: "JERSEY"},
  {isoCode: "JO", countryName: "JORDAN"},
  {isoCode: "KZ", countryName: "KAZAKHSTAN"},
  {isoCode: "KE", countryName: "KENYA"},
  {isoCode: "KI", countryName: "KIRIBATI"},
  {isoCode: "KP", countryName: "KOREA (THE DEMOCRATIC PEOPLE'S REPUBLIC OF)"},
  {isoCode: "KR", countryName: "KOREA (THE REPUBLIC OF)"},
  {isoCode: "KW", countryName: "KUWAIT"},
  {isoCode: "KG", countryName: "KYRGYZSTAN"},
  {isoCode: "LA", countryName: "LAO PEOPLE'S DEMOCRATIC REPUBLIC (THE)"},
  {isoCode: "LV", countryName: "LATVIA"},
  {isoCode: "LB", countryName: "LEBANON"},
  {isoCode: "LS", countryName: "LESOTHO"},
  {isoCode: "LR", countryName: "LIBERIA"},
  {isoCode: "LY", countryName: "LIBYA"},
  {isoCode: "LI", countryName: "LIECHTENSTEIN"},
  {isoCode: "LT", countryName: "LITHUANIA"},
  {isoCode: "LU", countryName: "LUXEMBOURG"},
  {isoCode: "MO", countryName: "MACAO"},
  {isoCode: "MG", countryName: "MADAGASCAR"},
  {isoCode: "MW", countryName: "MALAWI"},
  {isoCode: "MY", countryName: "MALAYSIA"},
  {isoCode: "MV", countryName: "MALDIVES"},
  {isoCode: "ML", countryName: "MALI"},
  {isoCode: "MT", countryName: "MALTA"},
  {isoCode: "MH", countryName: "MARSHALL ISLANDS (THE)"},
  {isoCode: "MQ", countryName: "MARTINIQUE"},
  {isoCode: "MR", countryName: "MAURITANIA"},
  {isoCode: "MU", countryName: "MAURITIUS"},
  {isoCode: "YT", countryName: "MAYOTTE"},
  {isoCode: "MX", countryName: "MEXICO"},
  {isoCode: "FM", countryName: "MICRONESIA (FEDERATED STATES OF)"},
  {isoCode: "MD", countryName: "MOLDOVA (THE REPUBLIC OF)"},
  {isoCode: "MC", countryName: "MONACO"},
  {isoCode: "MN", countryName: "MONGOLIA"},
  {isoCode: "ME", countryName: "MONTENEGRO"},
  {isoCode: "MS", countryName: "MONTSERRAT"},
  {isoCode: "MA", countryName: "MOROCCO"},
  {isoCode: "MZ", countryName: "MOZAMBIQUE"},
  {isoCode: "MM", countryName: "MYANMAR"},
  {isoCode: "NA", countryName: "NAMIBIA"},
  {isoCode: "NR", countryName: "NAURU"},
  {isoCode: "NP", countryName: "NEPAL"},
  {isoCode: "NL", countryName: "NETHERLANDS (THE)"},
  {isoCode: "NC", countryName: "NEW CALEDONIA"},
  {isoCode: "NZ", countryName: "NEW ZEALAND"},
  {isoCode: "NI", countryName: "NICARAGUA"},
  {isoCode: "NE", countryName: "NIGER (THE)"},
  {isoCode: "NG", countryName: "NIGERIA"},
  {isoCode: "NU", countryName: "NIUE"},
  {isoCode: "NF", countryName: "NORFOLK ISLAND"},
  {isoCode: "MP", countryName: "NORTHERN MARIANA ISLANDS (THE)"},
  {isoCode: "NO", countryName: "NORWAY"},
  {isoCode: "OM", countryName: "OMAN"},
  {isoCode: "PK", countryName: "PAKISTAN"},
  {isoCode: "PW", countryName: "PALAU"},
  {isoCode: "PS", countryName: "PALESTINE, STATE OF"},
  {isoCode: "PA", countryName: "PANAMA"},
  {isoCode: "PG", countryName: "PAPUA NEW GUINEA"},
  {isoCode: "PY", countryName: "PARAGUAY"},
  {isoCode: "PE", countryName: "PERU"},
  {isoCode: "PH", countryName: "PHILIPPINES (THE)"},
  {isoCode: "PN", countryName: "PITCAIRN"},
  {isoCode: "PL", countryName: "POLAND"},
  {isoCode: "PT", countryName: "PORTUGAL"},
  {isoCode: "PR", countryName: "PUERTO RICO"},
  {isoCode: "QA", countryName: "QATAR"},
  {isoCode: "MK", countryName: "REPUBLIC OF NORTH MACEDONIA"},
  {isoCode: "RO", countryName: "ROMANIA"},
  {isoCode: "RU", countryName: "RUSSIAN FEDERATION (THE)"},
  {isoCode: "RW", countryName: "RWANDA"},
  {isoCode: "RE", countryName: "RÉUNION"},
  {isoCode: "BL", countryName: "SAINT BARTHÉLEMY"},
  {isoCode: "SH", countryName: "SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA"},
  {isoCode: "KN", countryName: "SAINT KITTS AND NEVIS"},
  {isoCode: "LC", countryName: "SAINT LUCIA"},
  {isoCode: "MF", countryName: "SAINT MARTIN (FRENCH PART)"},
  {isoCode: "PM", countryName: "SAINT PIERRE AND MIQUELON"},
  {isoCode: "VC", countryName: "SAINT VINCENT AND THE GRENADINES"},
  {isoCode: "WS", countryName: "SAMOA"},
  {isoCode: "SM", countryName: "SAN MARINO"},
  {isoCode: "ST", countryName: "SAO TOME AND PRINCIPE"},
  {isoCode: "SA", countryName: "SAUDI ARABIA"},
  {isoCode: "SN", countryName: "SENEGAL"},
  {isoCode: "RS", countryName: "SERBIA"},
  {isoCode: "SC", countryName: "SEYCHELLES"},
  {isoCode: "SL", countryName: "SIERRA LEONE"},
  {isoCode: "SG", countryName: "SINGAPORE"},
  {isoCode: "SX", countryName: "SINT MAARTEN (DUTCH PART)"},
  {isoCode: "SK", countryName: "SLOVAKIA"},
  {isoCode: "SI", countryName: "SLOVENIA"},
  {isoCode: "SB", countryName: "SOLOMON ISLANDS"},
  {isoCode: "SO", countryName: "SOMALIA"},
  {isoCode: "ZA", countryName: "SOUTH AFRICA"},
  {isoCode: "GS", countryName: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS"},
  {isoCode: "SS", countryName: "SOUTH SUDAN"},
  {isoCode: "ES", countryName: "SPAIN"},
  {isoCode: "LK", countryName: "SRI LANKA"},
  {isoCode: "SD", countryName: "SUDAN (THE)"},
  {isoCode: "SR", countryName: "SURINAME"},
  {isoCode: "SJ", countryName: "SVALBARD AND JAN MAYEN"},
  {isoCode: "SE", countryName: "SWEDEN"},
  {isoCode: "CH", countryName: "SWITZERLAND"},
  {isoCode: "SY", countryName: "SYRIAN ARAB REPUBLIC"},
  {isoCode: "TW", countryName: "TAIWAN (PROVINCE OF CHINA)"},
  {isoCode: "TJ", countryName: "TAJIKISTAN"},
  {isoCode: "TZ", countryName: "TANZANIA, UNITED REPUBLIC OF"},
  {isoCode: "TH", countryName: "THAILAND"},
  {isoCode: "TL", countryName: "TIMOR-LESTE"},
  {isoCode: "TG", countryName: "TOGO"},
  {isoCode: "TK", countryName: "TOKELAU"},
  {isoCode: "TO", countryName: "TONGA"},
  {isoCode: "TT", countryName: "TRINIDAD AND TOBAGO"},
  {isoCode: "TN", countryName: "TUNISIA"},
  {isoCode: "TR", countryName: "TURKEY"},
  {isoCode: "TM", countryName: "TURKMENISTAN"},
  {isoCode: "TC", countryName: "TURKS AND CAICOS ISLANDS (THE)"},
  {isoCode: "TV", countryName: "TUVALU"},
  {isoCode: "UG", countryName: "UGANDA"},
  {isoCode: "UA", countryName: "UKRAINE"},
  {isoCode: "AE", countryName: "UNITED ARAB EMIRATES (THE)"},
  {isoCode: "GB", countryName: "UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND (THE)"},
  {isoCode: "UM", countryName: "UNITED STATES MINOR OUTLYING ISLANDS (THE)"},
  {isoCode: "US", countryName: "UNITED STATES OF AMERICA (THE)"},
  {isoCode: "UY", countryName: "URUGUAY"},
  {isoCode: "UZ", countryName: "UZBEKISTAN"},
  {isoCode: "VU", countryName: "VANUATU"},
  {isoCode: "VE", countryName: "VENEZUELA (BOLIVARIAN REPUBLIC OF)"},
  {isoCode: "VN", countryName: "VIET NAM"},
  {isoCode: "VG", countryName: "VIRGIN ISLANDS (BRITISH)"},
  {isoCode: "VI", countryName: "VIRGIN ISLANDS (U.S.)"},
  {isoCode: "WF", countryName: "WALLIS AND FUTUNA"},
  {isoCode: "EH", countryName: "WESTERN SAHARA"},
  {isoCode: "YE", countryName: "YEMEN"},
  {isoCode: "ZM", countryName: "ZAMBIA"},
  {isoCode: "ZW", countryName: "ZIMBABWE"},
  {isoCode: "AX", countryName: "ÅLAND ISLANDS"}
];

export default CountryCodes
