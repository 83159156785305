import * as React from 'react'
import { useState } from 'react'
import styles from "./RedeemCodeAsLoggedInUser.module.scss";

type Props = {
  redeemCodeHandle: () => void;
}

const RedeemCodeAsLoggedInUser: React.FC<Props> = props => {

  const [isSubmitting, setSubmitting] = useState(false);

  const handleOnClick = () => {
    setSubmitting(true);
    props.redeemCodeHandle();
    setSubmitting(false);
  };

  return <div className={styles.container}>
    <div className="text-center">
      <div>
        <img src={`${process.env.PUBLIC_URL}/sml_2.png`} className="sml-logo mb-5" alt="SoftwareMill"/>
      </div>
      <div>
        <button type="button" className="btn btn-lg btn-outline-primary" onClick={handleOnClick}
                disabled={isSubmitting}>
          Redeem the code as currently logged in user
        </button>
      </div>
    </div>
  </div>
};
export default RedeemCodeAsLoggedInUser;
