import { TrainerDto, WorkshopData } from "./common/types";
import {StaticRoutes} from "../common/Enums";

export const andrzejSocialMedia = {
  twitter: "https://twitter.com/aludwikowski",
  linkedIn: "https://www.linkedin.com/in/andrzej-ludwikowski-6015ab71",
  github: "https://github.com/aludwiko"
};

export const andrzej: TrainerDto = {
  name: "Andrzej Ludwikowski",
  bioId: "trainer.andrzej.description",
  image: "andrzej.png",
  socialMedia: andrzejSocialMedia
};

export const gatlingData: WorkshopData = {
  titleId: "gatling.title",
  descriptionId: "gatling.workshop.description1",
  trainer: andrzej,
  videoLink: "https://youtu.be/Jln3xsTpCs8",
  logo: "gatling-logo.svg",
  link: StaticRoutes.Gatling
};

export const gatlingDescription = {
  description1Id: "gatling.workshop.description1",
  description2Id: "gatling.workshop.description2",
  practicalKnowledge: "gatling.practical.knowledge",
  whyLabel: "gatling.why.label",
  whyContent: "gatling.why.content",
  when: "gatling.when",
  howJoin: "gatling.how.to.join.content",
  howItLooks: "gatling.how.it.looks",
  otherLabel: "gatling.why.in.scala.label",
  otherContent: "gatling.why.in.scala.content"
};

export const gatlingAgenda = [
  {
    chapterId: "gatling.agenda.chapter1.title",
    title: "",
    items: [
      "gatling.agenda.chapter1.content.item1",
      "gatling.agenda.chapter1.content.item2",
      "gatling.agenda.chapter1.content.item3"
    ]
  },
  {
    chapterId: "gatling.agenda.chapter2.title",
    title: "",
    items: [
      "gatling.agenda.chapter2.content.item1",
      "gatling.agenda.chapter2.content.item2",
      "gatling.agenda.chapter2.content.item3",
      "gatling.agenda.chapter2.content.item4"
    ]
  },
  {
    chapterId: "gatling.agenda.chapter3.title",
    title: "",
    items: [
      "gatling.agenda.chapter3.content.item1",
      "gatling.agenda.chapter3.content.item2",
      "gatling.agenda.chapter3.content.item3",
    ]
  },
  {
    chapterId: "gatling.agenda.chapter4.title",
    title: "",
    items: [
      "gatling.agenda.chapter3.content.item1",
    ]
  },
  {
    chapterId: "gatling.agenda.chapter5.title",
    title: "",
    items: [
      "gatling.agenda.chapter5.content.item1",
      "gatling.agenda.chapter5.content.item2",
      "gatling.agenda.chapter5.content.item3",
      "gatling.agenda.chapter5.content.item4"
    ]
  },
  {
    chapterId: "gatling.agenda.chapter6.title",
    title: "",
    items: [
      "gatling.agenda.chapter2.content.item1",
    ]
  },
  {
    chapterId: "gatling.agenda.chapter7.title",
    title: "",
    items: [
      "gatling.agenda.chapter7.content.item1",
      "gatling.agenda.chapter7.content.item2",
    ]
  },
  {
    chapterId: "gatling.agenda.chapter8.title",
    title: "",
    items: [
      "gatling.agenda.chapter8.content.item1",
      "gatling.agenda.chapter8.content.item2",
      "gatling.agenda.chapter8.content.item3",
    ]
  },
  {
    chapterId: "gatling.agenda.chapter9.title",
    title: "",
    items: [
      "gatling.agenda.chapter9.content.item1",
      "gatling.agenda.chapter9.content.item2",
    ]
  },
  {
    chapterId: "gatling.agenda.chapter10.title",
    title: "",
    items: [
      "gatling.agenda.chapter10.content.item1",
      "gatling.agenda.chapter10.content.item2",
      "gatling.agenda.chapter10.content.item3",
    ]
  },
];

export const akkaTypedDescription = {
  description1Id: "akka.typed.workshop.description1",
  description2Id: "akka.typed.workshop.description2",
  practicalKnowledge: "akka.typed.practical.knowledge.content",
  whyLabel: "akka.typed.why.label",
  whyContent: "akka.typed.why.content",
  when: "akka.typed.when.content",
  howJoin: "akka.typed.how.to.join.content",
  howItLooks: "akka.typed.how.it.looks",
  otherLabel: "akka.typed.next.item1.label",
  otherContent: "akka.typed.next.item1.content"
};

export const akkaTypedAgenda = [
  {
    chapterId: "akka.typed.agenda.chapter1",
    title: "",
    items: []
  },
  {
    chapterId: "akka.typed.agenda.chapter2",
    title: "",
    items: []
  },
  {
    chapterId: "akka.typed.agenda.chapter3",
    title: "",
    items: []
  },
  {
    chapterId: "akka.typed.agenda.chapter4",
    title: "",
    items: []
  },
  {
    chapterId: "akka.typed.agenda.chapter5",
    title: "",
    items: []
  },
  {
    chapterId: "akka.typed.agenda.chapter6",
    title: "",
    items: []
  },
  {
    chapterId: "akka.typed.agenda.chapter7",
    title: "",
    items: []
  },
  {
    chapterId: "akka.typed.agenda.chapter8",
    title: "",
    items: []
  },
  {
    chapterId: "akka.typed.agenda.chapter9",
    title: "",
    items: []
  },
  {
    chapterId: "akka.typed.agenda.chapter10",
    title: "",
    items: []
  },
  {
    chapterId: "akka.typed.agenda.chapter11",
    title: "",
    items: []
  },
  {
    chapterId: "akka.typed.agenda.chapter12",
    title: "",
    items: []
  },
  {
    chapterId: "akka.typed.agenda.chapter13",
    title: "",
    items: []
  },
  {
    chapterId: "akka.typed.agenda.chapter14",
    title: "",
    items: []
  },
  {
    chapterId: "akka.typed.agenda.chapter15",
    title: "",
    items: []
  },
];

export const eventSourcingData: WorkshopData = {
  titleId: "event.sourcing.title",
  descriptionId: "event.sourcing.workshop.description1",
  trainer: andrzej,
  videoLink: "",
  logo: "",
  link: StaticRoutes.EventSourcing
};
export const eventSourcingDescription = {
  description1Id: "event.sourcing.workshop.description1",
  description2Id: "event.sourcing.workshop.description2",
  practicalKnowledge: "event.sourcing.practical.knowledge.content",
  whyLabel: "event.sourcing.why.label",
  whyContent: "event.sourcing.why.content",
  when: "event.sourcing.when.content",
  howJoin: "event.sourcing.how.to.join.content",
  howItLooks: "event.sourcing.how.it.looks",
  otherLabel: "event.sourcing.next.item1.label",
  otherContent: "event.sourcing.next.item1.content"
};

export const eventSourcingAgenda = [
  {
    chapterId: "event.sourcing.agenda.chapter1",
    title: "",
    items: []
  },
  {
    chapterId: "event.sourcing.agenda.chapter2",
    title: "",
    items: ["event.sourcing.agenda.chapter2.item1", "event.sourcing.agenda.chapter2.item2"]
  },
  {
    chapterId: "event.sourcing.agenda.chapter3",
    title: "",
    items: ["event.sourcing.agenda.chapter3.item1",
      "event.sourcing.agenda.chapter3.item2",
      "event.sourcing.agenda.chapter3.item3",
      "event.sourcing.agenda.chapter3.item4",
      "event.sourcing.agenda.chapter3.item5",
      "event.sourcing.agenda.chapter3.item6"]
  },
  {
    chapterId: "event.sourcing.agenda.chapter4",
    title: "",
    items: ["event.sourcing.agenda.chapter4.item1",
      "event.sourcing.agenda.chapter4.item2"
    ]
  },
  {
    chapterId: "event.sourcing.agenda.chapter5",
    title: "",
    items: ["event.sourcing.agenda.chapter5.item1",
      "event.sourcing.agenda.chapter5.item2",
      "event.sourcing.agenda.chapter5.item3",
      "event.sourcing.agenda.chapter5.item4",
      "event.sourcing.agenda.chapter5.item5",
      "event.sourcing.agenda.chapter5.item6"
    ]
  },
  {
    chapterId: "event.sourcing.agenda.chapter6",
    title: "",
    items: ["event.sourcing.agenda.chapter6.item1",
      "event.sourcing.agenda.chapter6.item2",
      "event.sourcing.agenda.chapter6.item3",
      "event.sourcing.agenda.chapter6.item4",
      "event.sourcing.agenda.chapter6.item5"
    ]
  },
  {
    chapterId: "event.sourcing.agenda.chapter7",
    title: "",
    items: ["event.sourcing.agenda.chapter7.item1",
      "event.sourcing.agenda.chapter7.item2"
    ]
  },
  {
    chapterId: "event.sourcing.agenda.chapter8",
    title: "",
    items: ["event.sourcing.agenda.chapter8.item1",
      "event.sourcing.agenda.chapter8.item2",
      "event.sourcing.agenda.chapter8.item3"
    ]
  },
  {
    chapterId: "event.sourcing.agenda.chapter9",
    title: "",
    items: ["event.sourcing.agenda.chapter9.item1",
      "event.sourcing.agenda.chapter9.item2",
      "event.sourcing.agenda.chapter9.item3",
      "event.sourcing.agenda.chapter9.item4",
      "event.sourcing.agenda.chapter9.item5"
    ]
  },
  {
    chapterId: "event.sourcing.agenda.chapter10",
    title: "",
    items: ["event.sourcing.agenda.chapter10.item1",
      "event.sourcing.agenda.chapter10.item2"
    ]
  },
  {
    chapterId: "event.sourcing.agenda.chapter11",
    title: "",
    items: []
  },
  {
    chapterId: "event.sourcing.agenda.chapter12",
    title: "",
    items: []
  },
  {
    chapterId: "event.sourcing.agenda.chapter13",
    title: "",
    items: []
  },
  {
    chapterId: "event.sourcing.agenda.chapter14",
    title: "",
    items: []
  },
  {
    chapterId: "event.sourcing.agenda.chapter15",
    title: "",
    items: ["event.sourcing.agenda.chapter15.item1",
      "event.sourcing.agenda.chapter15.item2",
      "event.sourcing.agenda.chapter15.item3"
    ]
  },
  {
    chapterId: "event.sourcing.agenda.chapter16",
    title: "",
    items: ["event.sourcing.agenda.chapter16.item1",
      "event.sourcing.agenda.chapter16.item2"
    ]
  },
  {
    chapterId: "event.sourcing.agenda.chapter17",
    title: "",
    items: ["event.sourcing.agenda.chapter17.item1",
      "event.sourcing.agenda.chapter17.item2",
      "event.sourcing.agenda.chapter17.item3"
    ]
  },
  {
    chapterId: "event.sourcing.agenda.chapter18",
    title: "",
    items: ["event.sourcing.agenda.chapter18.item1",
      "event.sourcing.agenda.chapter18.item2"
    ]
  },
  {
    chapterId: "event.sourcing.agenda.chapter19",
    title: "",
    items: ["event.sourcing.agenda.chapter19.item1",
      "event.sourcing.agenda.chapter19.item2"
    ]
  }
];

export const akkaClusterDescription = {
  description1Id: "akka.cluster.workshop.description1",
  description2Id: "akka.cluster.workshop.description2",
  practicalKnowledge: "akka.cluster.practical.knowledge.content",
  whyLabel: "akka.cluster.why.label",
  whyContent: "akka.cluster.why.content",
  when: "akka.cluster.when.content",
  howJoin: "akka.cluster.how.to.join.content",
  howItLooks: "akka.cluster.how.it.looks",
  otherLabel: "akka.cluster.next.item1.label",
  otherContent: "akka.cluster.next.item1.content"
};

export const akkaClusterAgenda = [
  {
    chapterId: "akka.cluster.agenda.chapter1",
    title: "",
    items: []
  },
  {
    chapterId: "akka.cluster.agenda.chapter2",
    title: "",
    items: []
  },
  {
    chapterId: "akka.cluster.agenda.chapter3",
    title: "",
    items: ["akka.cluster.agenda.chapter3.item1", "akka.cluster.agenda.chapter3.item2", "akka.cluster.agenda.chapter3.item3", "akka.cluster.agenda.chapter3.item4", "akka.cluster.agenda.chapter3.item5"]
  },
  {
    chapterId: "akka.cluster.agenda.chapter4",
    title: "",
    items: []
  },
  {
    chapterId: "akka.cluster.agenda.chapter5",
    title: "",
    items: ["akka.cluster.agenda.chapter5.item1", "akka.cluster.agenda.chapter5.item2"]
  },
  {
    chapterId: "akka.cluster.agenda.chapter6",
    title: "",
    items: []
  },
  {
    chapterId: "akka.cluster.agenda.chapter7",
    title: "",
    items: []
  }
];
