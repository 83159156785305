import React, { useCallback, useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './Login/Login';
import NotFound from './NotFound/NotFound';
import ProtectedRoute from './ProtectedRoute/ProtectedRoute';
import RecoverLostPassword from './RecoverLostPassword/RecoverLostPassword';
import Register from './Register/Register';
import Spinner from './Spinner/Spinner';
import Welcome from './Welcome/Welcome';
import ProfileDetails from './ProfileDetails/ProfileDetails';
import PasswordDetails from './PasswordDetails/PasswordDetails';
import Footer from './Footer/Footer';
import { getCurrentUser } from './UserService/UserService';
import { getAppVersion } from './VersionService/VersionService';
import { User, Version } from './types/Types';
import { AppContext } from './Contexts/AppContext';
import BuyCourse from "./BuyCourse/BuyCourse";
import MyCoursesRoute from "./MyCourses/MyCoursesRoute";
import { Either, Left } from "ts-matches";
import RedeemCode from "./RedeemCode/RedeemCode";
import GatlingCourse from "./LandingPages/Gatling";
import { IntlProvider } from "react-intl";
// import Polish from './i18n/pl.json';
// import { English } from './i18n/localizations/en';
import AkkaTyped from "./LandingPages/AkkaTyped/AkkaTyped";
import EventSourcing from "./LandingPages/EventSourcing/EventSourcing";
import { LocalizedSwitch } from "./i18n/components/LocalizedSwitch";
import { StaticRoutes } from "./common/Enums";
import AkkaCluster from "./LandingPages/AkkaCluster/AkkaCluster";
import ScrollToTop from "./ScrollToTop/ScrollToTop";

const App: React.FC = () => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState<User>({ email: '', login: '' });
  const [isLoadingAuthInfo, setLoadingAuthInfo] = useState(true);
  const [version, setVersion] = useState<Either<string, Version>>(Left.of(''));
  const location = useLocation();
  const [locale, setLocale] = useState(navigator.language);

  useEffect(() => {
    (async () => {
      await resolveCurrentUser();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const v = await getAppVersion();
      setVersion(v);
    })();
  }, []);

  const resolveCurrentUser = async () => {
    const userResult = await getCurrentUser();

    userResult.fold({
      left: () => {
        localStorage.removeItem('apiKey');
      },
      right: user => {
        setLoggedIn(true);
        setUser(user);
      }
    });
    setLoadingAuthInfo(false);
  };

  const updateUserInfo = (user: User) => {
    setUser(user);
  };

  const onLoggedIn = useCallback(async () => {
    await resolveCurrentUser();
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem('apiKey');
    setLoggedIn(false);
    setUser({ email: '', login: '' })
  }, []);

  const notifySuccess = (msg: string) => {
    toast.success(msg);
  };

  const notifyError = (msg: string) => {
    toast.error(msg);
  };

  return (
    isLoadingAuthInfo ? <Spinner/>
      : <div className="App">
        <AppContext.Provider value={{ user, isLoggedIn, locale }}>
            <div className="Main">
              <ScrollToTop/>
              <LocalizedSwitch>
                <Route exact path={StaticRoutes.Home} render={() => <Welcome/>}/>
                <Route exact path={StaticRoutes.Gatling} render={() => <GatlingCourse/>}/>
                <Route exact path={StaticRoutes.AkkaTyped} render={() => <AkkaTyped/>}/>
                <Route exact path={StaticRoutes.EventSourcing} render={() => <EventSourcing/>}/>
                <Route exact path={StaticRoutes.AkkaCluster} render={() => <AkkaCluster/>}/>
                <ProtectedRoute isLoggedIn={isLoggedIn} path="/my-courses" component={MyCoursesRoute}/>
                <Route exact path="/buy"
                       render={() => <BuyCourse notifyError={notifyError} notifySuccess={notifySuccess}/>}/>
                <Route path="/login" render={() =>
                  <Login onLoggedIn={onLoggedIn} notifyError={notifyError}/>
                }/>
                <Route path="/register" render={() =>
                  <Register notifyError={notifyError} notifySuccess={notifySuccess}/>
                }/>
                <ProtectedRoute isLoggedIn={isLoggedIn} path="/profile" render={() =>
                  <div>
                    <ProfileDetails onUserUpdated={updateUserInfo}
                                    notifyError={notifyError} notifySuccess={notifySuccess}/>
                    <PasswordDetails notifyError={notifyError} notifySuccess={notifySuccess}/>
                  </div>
                }/>
                <Route path="/recover-lost-password" render={() =>
                  <RecoverLostPassword notifyError={notifyError} notifySuccess={notifySuccess}/>
                }/>
                <Route path="/redeem/:code"
                       render={() => <RedeemCode onLoggedIn={onLoggedIn} notifySuccess={notifySuccess}
                                                 notifyError={notifyError}/>}/>
                <Route render={() => <NotFound/>}/>
              </LocalizedSwitch>
            </div>
            {location.pathname !== '/login' && location.pathname !== '/register' ?
              <Footer version={version}/> : <></>}
            <ToastContainer/>
        </AppContext.Provider>
      </div>
  );
};

export default App;
