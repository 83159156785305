import * as React from 'react';
import { useEffect, useState } from 'react';
import BuyForm from "./BuyForm";
import { getActiveSale } from "../SaleService/SaleService";
import PaymentCompleted from "./PaymentCompleted";
import { usePromise } from "react-use-promise-matcher";
import { GetActiveSaleOutput } from "../types/Types";
import Spinner from "../Spinner/Spinner";

type Props = {
  notifySuccess: (msg: string) => void;
  notifyError: (msg: string) => void;
}

const BuyCourse: React.FC<Props> = ({ notifySuccess, notifyError }) => {
  const [isLoading, setLoading] = useState(true);
  const { result, load } = usePromise<GetActiveSaleOutput, Error>(() => getActiveSale());
  const [courseName, setCourseName] = useState("");
  const [saleEnd, setSaleEnd] = useState("");
  const [orderId, setOrderId] = useState("");

  useEffect(() => {
    (async () => {
      load();
      // const activeSale = await getActiveSale();
      setLoading(false);
      // activeSale
    })();
    // getActiveSale().then(either => {
    //   either.fold({
    //     left(l) {
    //       // setSaleActive(false);
    //       notifyError("Couldn't find any active sale");
    //     },
    //     right(r) {
    //       // setSaleActive(true);
    //       setCourseName(r.courseName);
    //       setSaleEnd(new Date(r.end).toLocaleDateString())
    //     }
    //   })
    // })
  }, []);

  const handlePaymentCompletion = (data: any) => {
    notifySuccess("Payment approved! :)");
    setOrderId(data.orderID);
  };

  return result.match({
    Loading: () => <Spinner/>,
    Rejected: () => <h1 className="m-5 text-center text-monospace">No active sale exists</h1>,
    Resolved: sale => {
      const saleEnd = new Date(sale.end).toLocaleDateString();
      return (
        <>
          {orderId ? <PaymentCompleted payPalOrderId={orderId}/> :
            <>
              <div className="container text-monospace">
                <h1 className="m-4 text-center">{courseName}</h1>
                <div className="text-right">Sale ends on: {saleEnd}</div>
              </div>
              <BuyForm onSuccess={handlePaymentCompletion}
                       notifyError={notifyError}/>
            </>}
        </>)
    }
  });
  /*  return isLoading ? <p>Loading...</p> :
      !isSaleActive ? <h1 className="m-5 text-center text-monospace">No active sale exists</h1> :
        <>
          {orderId ? <PaymentCompleted payPalOrderId={orderId}/> :
            <>
              <div className="container text-monospace">
                <h1 className="m-4 text-center">{courseName}</h1>
                <div className="text-right">Sale ends on: {saleEnd}</div>
              </div>
              <BuyForm onSuccess={handlePaymentCompletion}
                       notifyError={notifyError}/>
            </>
          }
        </>
   */
};

export default BuyCourse;
