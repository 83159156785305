import * as React from 'react';
import { ChapterModule, ModulePage } from "../../types/Types";
import styles from './Module.module.scss';
import { Link } from "react-router-dom";

type Props = {
  module: ChapterModule;
  coursePath: string;
  chapterPath: string;
}

const Module: React.FC<Props> = ({ module, coursePath, chapterPath }) => {
  return (
    <div className={`container ${styles.module}`}>
      <h2>{module.title}</h2>
      <table className="table">
        <thead>
        <tr className={styles.moduleHeader}>
          <th scope="col">DONE</th>
          <th scope="col">TITLE</th>
          <th scope="col">MOVIE</th>
          <th scope="col">EXERCISES</th>
          <th scope="col">% COMPLETE</th>
        </tr>
        </thead>
        <tbody>
        {module.pages.map((page: ModulePage) => <tr>
          <td>
            {page.finished ? <i className={`fa fa-check-circle ${styles.pageDone}`}/> :
              <i className={`fa fa-circle ${styles.pageUndone}`}/>}
          </td>
          <td>
            <Link to={`/my-courses/${coursePath}/${chapterPath}/${module.path}/${page.path}`}>{page.title}</Link>
          </td>
          <td>{page.secondsToFinish}</td>
          <td></td>
          <td></td>
        </tr>)}
        </tbody>
      </table>
    </div>
  )
};

export default Module;
