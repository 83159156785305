import * as React from 'react';
import Testimonials from "../LandingPages/Testimonials";
import MainWorkshop from "./Workshops/MainWorkshop";
import OtherWorkshops from "./Workshops/OtherWorkshops";
import Header from "../LandingPages/common/Header/Header";
import { eventSourcingData } from "../LandingPages/data";

const Welcome: React.FC = () =>
  <div className="welcome-container">
    <Header />
    <div>
      <OtherWorkshops theme="Light" />
      <MainWorkshop data={eventSourcingData} />
    </div>
    <Testimonials />
  </div>;

export default Welcome;
