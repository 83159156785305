import * as React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

type WorkshopProps = {
  titleId: string;
  btnTextId: string;
  link: string;
}

const Workshop: React.FC<WorkshopProps> = ({ titleId, btnTextId, link }) => {

  return (
    <div className="workshop">
      <div className="workshop-title"><FormattedMessage id={titleId}/></div>
      <Link to={link} type="submit"><FormattedMessage id={btnTextId}/></Link>
    </div>
  )
};

const OtherWorkshops: React.FC<{ theme?: "Dark" | "Light" }> = ({ theme = "Dark" }) => {
  const { locale, formatMessage } = useIntl();

  return (
    <div className={theme === "Dark" ? "other-workshops" : "other-workshops-light"}>
      <h1><FormattedMessage id="site.other.workshops"/></h1>
      <div className="workshops">
        <Workshop titleId="site.other.workshops.akka.title"
                  btnTextId="workshop.see.more"
                  link={`/${locale}` + formatMessage({ id: "routes.akka.typed" })}/>
        <Workshop titleId="site.other.workshops.es.title"
                  btnTextId="workshop.see.more"
                  link={`/${locale}` + formatMessage({ id: "routes.event.sourcing" })}/>
        <Workshop titleId="site.other.workshops.akka.cluster.title"
                  btnTextId="workshop.see.more"
                  link={`/${locale}` + formatMessage({ id: "routes.akka.cluster" })}/>
        <Workshop titleId="site.other.workshops.gatling.title"
                  btnTextId="workshop.see.more"
                  link={`/${locale}` + formatMessage({ id: "routes.gatling" })}/>
      </div>
    </div>
  )
};

export default OtherWorkshops;
