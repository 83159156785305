const baseRoutes = {
  'routes.home': '/',
  'routes.gatling': '/gatling-workshop',
  'routes.akka.typed': '/akka-typed-workshop',
  'routes.event.sourcing': '/event-sourcing-workshop',
  'routes.akka.cluster': '/akka-cluster-workshop',

  "site.title": "SoftwareMill Academy",
  "site.subtitle": "Dive deep into practical know-how and join hands-on Functional Programming trainings.",
  "footer.softwaremill": "© 2020 SoftwareMill Academy. All rights reserved.",
  "site.newsletter": "Join our Newsletter!",
  "site.other.workshops": "Our workshops",
  "site.other.workshops.akka.title": "Akka Typed Fundamentals",
  "site.other.workshops.akka.subtitle": "Would you like to learn Akka Typed?",
  "site.other.workshops.es.title": "Reactive Event Sourcing",
  "site.other.workshops.es.subtitle": "Would you like to learn Event Sourcing with Akka Persistence?",
  "site.other.workshops.akka.cluster.title": "Akka Cluster",
  "site.other.workshops.akka.cluster.subtitle": "Would you like to learn Akka Cluster?",
  "site.other.workshops.gatling.title": "Performance tests in Gatling",
  "site.other.workshops.gatling.subtitle": "Would you like to learn Gatling?",

  "workshop.description.label": "Workshop Description",
  "workshop.what.you.will.learn": "What you will learn",
  "workshop.practical.knowledge.label": "Practical knowledge",
  "workshop.when.label": "When?",
  "workshop.how.to.join.label": "How to join?",
  "workshop.how.it.looks.label": "Workshop format",
  "workshop.trainer.about": "About Trainer",
  "workshop.coming.soon": "Coming soon...",
  "workshop.see.more": "See more",
  "workshop.sign.up.now": "Sign up now",
  "workshop.tickets": "Tickets",
  "workshop.buy.ticket": "Buy Ticket",
  "workshop.videos": "Video",
  "workshop.resources": "Other resources",

  "menu.agenda": "Agenda",
  "menu.videos": "Video",
  "menu.trainer": "Trainer",
  "menu.other.workshops": "Other Workshops",
  "menu.tickets": "Tickets",
  "trainer.andrzej.description": "Software Architect (Scala/Java), Consultant, Speaker & Trainer.\nCertified: Reactive Architect, Cassandra Architect, Kafka Developer",

  "gatling.title": "Performance Tests in Gatling",
  "gatling.subtitle": "Get practical knowledge of web application performance testing. Learn what are the most common performance testing mistakes and how to avoid them.",
  "gatling.workshop.description1": "<p>Training focuses on practical approach - you'll work on a real web application, cover it with tests and detect performance problems. You'll dive deep into the stress tests theory and will recognize the most common mistakes and learn how to avoid them when measuring performance.</p><p>Next to Apache JMeter and Locust, Gatling is one of the most popular performance testing tool. It offers solutions where other tools fail to deliver.</p>",
  "gatling.workshop.description2": "<p>With Gatling you can create a very readable, easy to maintain and composable performance tests. It also requires less resources to generate the appropriate load, which is a significant feature when testing cloud solutions.</p><p>Gatling uses <b>Scala</b>, but knowledge of this language is not necessary to join the training.",
  "gatling.practical.knowledge": "The training is geared towards practical exercises on an existing web application that needs to be cover with performance tests. Participants will also discover and analyze the most common performance bottlenecks.",
  "gatling.why.label": "Why Gatling?",
  "gatling.why.content": "Gatling is a free tool for performance and automation tests, which allows to create very readable, easy to maintain, and composable tests. Beside testing HTTP endpoints you can also use Gatling for testing WebSockets, JMS and other systems since this tool is very pluggable.",

  "gatling.when": "Online workshop is coming soon. Join the mailing list and be the first to learn about the schedule and other details.",
  "gatling.how.to.join.content": "Would you like to learn the ins and outs of Gatling? Send us an email to <a href=\"mailto:{email}\">{email}</a>. We'll work out the details and send you a dedicated workshop plan for you and your team.",
  "gatling.how.it.looks": "This is a 2 days training. In on-line version we use Zoom for voice and video communication and Slack for text chat.",
  "gatling.why.in.scala.label": "Scala?",
  "gatling.why.in.scala.content": "Yes, Gatling is based on Scala language, but knowing any programming language is enough to participate in this workshop. Participants will get additional materials to get familiar with the Scala syntax before the actual training.",
  "gatling.trainer.label": "Trainer: {name}",
  "gatling.agenda.chapter1.title": "Performance tests - theoretical basics",
  "gatling.agenda.chapter1.content.item1": "environment preparation, monitoring, logging, profiling",
  "gatling.agenda.chapter1.content.item2": "metrics - how to successfully compare the performance tests results and not to be fooled by the statistics",
  "gatling.agenda.chapter1.content.item3": "errors found in popular performance testing tools, such as the Coordinated Omission problem",
  "gatling.agenda.chapter2.title": "Sbt and Scala projects - basics",
  "gatling.agenda.chapter2.content.item1": "installation and configuration of basic tools",
  "gatling.agenda.chapter2.content.item2": "creating a new project with Gatling",
  "gatling.agenda.chapter2.content.item3": "project import to IDE (IntelliJ IDEA)",
  "gatling.agenda.chapter2.content.item4": "basic sbt commands for working with Gatling and Scala",
  "gatling.agenda.chapter3.title": "Gatling - basics",
  "gatling.agenda.chapter3.content.item1": "gatling architecture",
  "gatling.agenda.chapter3.content.item2": "tool comparison (JMeter, Selenium)",
  "gatling.agenda.chapter3.content.item3": "creating and running the first test scenario",
  "gatling.agenda.chapter4.title": "Automatic test generation based on HTTP traffic",
  "gatling.agenda.chapter4.content.item1": "gatling Recorder - configuration and connection",
  "gatling.agenda.chapter5.title": "Advanced Gatling functions",
  "gatling.agenda.chapter5.content.item1": "response validations",
  "gatling.agenda.chapter5.content.item2": "loops, conditional expression, randomness",
  "gatling.agenda.chapter5.content.item3": "virtual user session",
  "gatling.agenda.chapter5.content.item4": "error handling in responses",
  "gatling.agenda.chapter6.title": "Test quality",
  "gatling.agenda.chapter6.content.item1": "improving modularity, reusability, test composition",
  "gatling.agenda.chapter6.content.item2": "test maintainability and adaptability",
  "gatling.agenda.chapter7.title": "Production traffic simulation",
  "gatling.agenda.chapter7.content.item1": "generating test data",
  "gatling.agenda.chapter7.content.item2": "advanced traffic control generated by Gatling",
  "gatling.agenda.chapter8.title": "Performance test results",
  "gatling.agenda.chapter8.content.item1": "generating HTML reports with the results",
  "gatling.agenda.chapter8.content.item2": "useful metrics and charts",
  "gatling.agenda.chapter8.content.item3": "throughput vs latency",
  "gatling.agenda.chapter9.title": "Distributed tests",
  "gatling.agenda.chapter9.content.item1": "generating the maximum load from multiple machines simultaneously with Gatling",
  "gatling.agenda.chapter9.content.item2": "collecting and processing the results",
  "gatling.agenda.chapter9.content.item3": "alternative automation tools for distributed testing",
  "gatling.agenda.chapter10.title": "Asynchronous tests (optional)",
  "gatling.agenda.chapter10.content.item1": "asynchronous tests flow problems",
  "gatling.agenda.chapter10.content.item2": "testing WebSocket",
  "gatling.agenda.chapter10.content.item3": "testing JMS",

  "akka.typed.title": "Akka Typed Fundamentals (Java/Scala)",
  "akka.typed.subtitle": "One of Akka's biggest problems, which is lack of typed actors, has finally been solved. As of version 2.6.0, Akka Typed is already a mature library and is recommended as the default selection.",

  "akka.typed.workshop.description1": "<p>One of the biggest drawbacks of Akka Actors - the lack of type safety is finally addressed. Since 2.6.0 release Akka Typed is stable and recommended as the default choice when working with Akka stack. Let's explore important patterns and best practices while introducing typed actors to our system.</p>",
  "akka.typed.workshop.description2": "<p>As Akka reached a broad adoption on production systems, its users identified quite a few pain points that needed to be addressed. Let's practice how to leverage the new supervision model, improved actor lifecycle handling, refined timers and many more enhancements. During this workshop you will explore important patterns and takeaways we've learned while introducing typed actors to our system.</p>",
  "akka.typed.practical.knowledge.content": "The training is geared towards practical exercises with Akka Actor Typed library. Let's play with the new actors API, walk through the basics of behaviors, learn about actor lifecycle, supervision, scheduling and other fundamental concepts. The workshop could be delivered for Java or Scala programmers.",
  "akka.typed.why.label": "Why Akka Typed?",
  "akka.typed.why.content": "Akka stack is one of the most popular reactive platforms on the market. It was successfully introduced and battle-tested in many production solutions, where scaling and low-latency is a significant requirement. Actors are the fundamental part used by all the other libraries like Akka Cluster, Sharding, Persistence, Streams, etc.",
  "akka.typed.when.label": "When?",
  "akka.typed.when.content": "Online workshop is coming soon. Join the mailing list and be the first to learn about the schedule and other details.",
  "akka.typed.how.to.join.content": "Would you like to learn more about Akka Typed? Send us an email to <a href=\"mailto:{email}\">{email}</a>. We'll work out the details and send you a dedicated workshop plan for you and your team.",
  "akka.typed.how.it.looks": "This is a 1-2 days training, depending on the selected scope. In on-line version we use Zoom for voice and video communication and Slack for text chat.",
  "akka.typed.next.item1.label": "Requirements",
  "akka.typed.next.item1.content": "Basic knowledge of Java/Scala language. No previous knowledge of Akka stack is required, although users experienced with Classical Akka will also benefit from this workshop.",
  "akka.typed.next.item2.label": "",
  "akka.typed.next.item2.content": "",

  "akka.typed.agenda.chapter1": "Actor Model: basic theory",
  "akka.typed.agenda.chapter2": "Disadvantages of untyped actors",
  "akka.typed.agenda.chapter3": "Spawning actors",
  "akka.typed.agenda.chapter4": "sender() vs replyTo",
  "akka.typed.agenda.chapter5": "Actor behaviors",
  "akka.typed.agenda.chapter6": "Testing",
  "akka.typed.agenda.chapter7": "Typed actor lifecycle",
  "akka.typed.agenda.chapter8": "Designing actor API",
  "akka.typed.agenda.chapter9": "Error handling and supervision",
  "akka.typed.agenda.chapter10": "Creating and managing child actors",
  "akka.typed.agenda.chapter11": "Timers",
  "akka.typed.agenda.chapter12": "Protocol receptionist pattern",
  "akka.typed.agenda.chapter13": "Typed and untyped actors coexistence (optional)",
  "akka.typed.agenda.chapter14": "Stash (optional)",
  "akka.typed.agenda.chapter15": "Dispatchers (optional)",

  "event.sourcing.title": "Reactive Event Sourcing in Java/Scala",
  "event.sourcing.subtitle": "Wondering whether Event Sourcing will really solve your problems, how to start building solutions based on Event Sourcing, how to improve existing solution with Event Sourcing or how to avoid classic mistakes? This workshop will save you a lot of time and nerves.",

  "event.sourcing.workshop.description1": "<p>Event Sourcing is one of the most interesting patterns in application design.</p><p>Implementation of Event Sourcing affects different levels of the system thus the concept is often linked to the software architecture.</p><p>Right now, after signing up for the newsletter, you get a series of articles that will guide you through the ins and outs of Event Sourcing implementation.</p>" +
    "<p>Get the latest resources every few weeks and learn at your own pace.</p>" +

    "<p>At the end of the series you’ll know:</p>" +
    "<ul><li>the right tools for a quick Event Sourcing pattern implementation,</li>" +
    "<li>how to model your domain code without any Event Sourcing framework dependencies,</li>" +
    "<li>how to create consistent and scalable Event Sourced applications without optimistic (or pessimistic) locking,</li>" +
    "<li>how to do all of the above in Java with your favorite stack like Spring.</li></ul>" +
    "<p>Let’s get started!</p>",
  "event.sourcing.workshop.description2": "<p>The training focuses not only on Event Sourcing itself, but also on many other aspects of building distributed systems. The practical part can be carried out in Scala or Java depending on the needs of the group.</p>",
  "event.sourcing.practical.knowledge.content": "A practical Reactive Event Sourcing with Akka workshop that will give you ready-to-use production solutions and Event Sourcing theory background. Apart from learning the theory, you will really put your hands on the code. Exercises are not trivial and try to simulate production use-cases and problems.",
  "event.sourcing.why.label": "Reactive tech stack",
  "event.sourcing.why.content": "Reactive Event Sourcing workshop is based on the advanced and modern reactive stack, mainly: Akka Persistence, Akka Sharding, Akka Cluster, Akka Streams. It could be performed with Scala or Java language. It's not only about the stack but also about changing your mindset and thinking about events when designing an Event Sourcing based system.",
  "event.sourcing.when.label": "When?",
  "event.sourcing.when.content": "Online workshop is coming soon. Join the mailing list and be the first to learn about the schedule and other details.",
  "event.sourcing.how.to.join.content": "Would you like to learn more about Reactive Event Sourcing training? Send us an email to <a href=\"mailto:{email}\">{email}</a>. We'll work out the details and send you a dedicated workshop plan for you and your team.",
  "event.sourcing.how.it.looks": "This is a 2-3 days training, depending on the selected scope. In on-line version we use Zoom for voice and video communication and Slack for text chat.",
  "event.sourcing.next.item1.label": "Requirements",
  "event.sourcing.next.item1.content": "Basic knowledge of Java/Scala language. No previous knowledge of Akka stack is required.",
  "event.sourcing.next.item2.label": "",
  "event.sourcing.next.item2.content": "",

  "event.sourcing.agenda.chapter1": "Why Reactive",
  "event.sourcing.agenda.chapter2": "Reactive Microservices",
  "event.sourcing.agenda.chapter2.item1": "isolation of state, space, time, failure",
  "event.sourcing.agenda.chapter2.item2": "reactive microservices patterns",
  "event.sourcing.agenda.chapter3": "Consistency, Availability, Scalability",
  "event.sourcing.agenda.chapter3.item1": "performance vs scalability",
  "event.sourcing.agenda.chapter3.item2": "strong consistency vs eventual consistency",
  "event.sourcing.agenda.chapter3.item3": "contention and Amdahl's Law",
  "event.sourcing.agenda.chapter3.item4": "coherency and Gunther's Law",
  "event.sourcing.agenda.chapter3.item5": "sharding",
  "event.sourcing.agenda.chapter3.item6": "CRDTs",
  "event.sourcing.agenda.chapter4": "Messaging and Coordination",
  "event.sourcing.agenda.chapter4.item1": "synchronous vs asynchronous messaging",
  "event.sourcing.agenda.chapter4.item2": "delivery guarantees",
  "event.sourcing.agenda.chapter5": "Actor model",
  "event.sourcing.agenda.chapter5.item1": "Akka stack summary",
  "event.sourcing.agenda.chapter5.item2": "Actor basics",
  "event.sourcing.agenda.chapter5.item3": "Actor System",
  "event.sourcing.agenda.chapter5.item4": "Testing Actors",
  "event.sourcing.agenda.chapter5.item5": "Actor lifecycle",
  "event.sourcing.agenda.chapter5.item6": "Fault tolerance",
  "event.sourcing.agenda.chapter6": "Event Sourcing",
  "event.sourcing.agenda.chapter6.item1": "pros and cons of message driven vs event driven & event first approach",
  "event.sourcing.agenda.chapter6.item2": "CQRS",
  "event.sourcing.agenda.chapter6.item3": "different types of Event Sourcing implementations",
  "event.sourcing.agenda.chapter6.item4": "Command Sourcing",
  "event.sourcing.agenda.chapter6.item5": "Event Collaboration",
  "event.sourcing.agenda.chapter7": "Challenges with distributed Event Sourcing implementation",
  "event.sourcing.agenda.chapter7.item1": "storage consistency",
  "event.sourcing.agenda.chapter7.item2": "single writer principle",
  "event.sourcing.agenda.chapter8": "Akka Persistence",
  "event.sourcing.agenda.chapter8.item1": "basic concepts",
  "event.sourcing.agenda.chapter8.item2": "architecture",
  "event.sourcing.agenda.chapter8.item3": "domain separation",
  "event.sourcing.agenda.chapter9": "Aggregate implementation",
  "event.sourcing.agenda.chapter9.item1": "basic domain flow: command -> aggregate -> events",
  "event.sourcing.agenda.chapter9.item2": "basic application flow: service -> persistent actor -> aggregate -> DB",
  "event.sourcing.agenda.chapter9.item3": "persistent actor state",
  "event.sourcing.agenda.chapter9.item4": "domain API",
  "event.sourcing.agenda.chapter9.item5": "persistent actor API",
  "event.sourcing.agenda.chapter10": "Deduplication",
  "event.sourcing.agenda.chapter10.item1": "at-most-once deduplication",
  "event.sourcing.agenda.chapter10.item2": "exactly-once deduplication",
  "event.sourcing.agenda.chapter11": "Read path in Event Sourcing: querying persistent actor state",
  "event.sourcing.agenda.chapter12": "Persistent actor recovery path",
  "event.sourcing.agenda.chapter13": "Domain validation",
  "event.sourcing.agenda.chapter14": "Events enrichment",
  "event.sourcing.agenda.chapter15": "Projections",
  "event.sourcing.agenda.chapter15.item1": "Akka Persistent Query configuration",
  "event.sourcing.agenda.chapter15.item2": "Command Query Responsibility Segregation in Event Sourcing implementation",
  "event.sourcing.agenda.chapter15.item3": "reactive projections",
  "event.sourcing.agenda.chapter16": "Serialization",
  "event.sourcing.agenda.chapter16.item1": "serialization strategies",
  "event.sourcing.agenda.chapter16.item2": "schema evolution: backward compatibility & forward compatibility",
  "event.sourcing.agenda.chapter17": "Akka Cluster",
  "event.sourcing.agenda.chapter17.item1": "basic setup",
  "event.sourcing.agenda.chapter17.item2": "split brain",
  "event.sourcing.agenda.chapter17.item3": "deployment strategies",
  "event.sourcing.agenda.chapter18": "Akka Persistence Cassandra journal",
  "event.sourcing.agenda.chapter18.item1": "this could be also SQL, DynamoDB, Couchbase journal",
  "event.sourcing.agenda.chapter18.item2": "eventual consistency",
  "event.sourcing.agenda.chapter19": "Multi aggregate transactions with Saga Pattern",
  "event.sourcing.agenda.chapter19.item1": "choreography",
  "event.sourcing.agenda.chapter19.item2": "orchestration",

  "akka.cluster.title": "Akka Cluster",
  "akka.cluster.subtitle": "Let's play with the famous Akka Cluster technology using hands-on exercises that cover the most important functionalities.",

  "akka.cluster.workshop.description1": "<p>When working on distributed systems, sooner or later you will have to face the problem of scaling the application. In most cases, scaling is done simply by launching another instance of the application. Unfortunately, this approach does not always bring measurable benefits, because, e.g. a database will become a bottleneck very quickly. Yes, the database itself can be scaled/sharded as well, but that also does not solve all problems. And it certainly creates new challenges, such as data consistency.</p>",
  "akka.cluster.workshop.description2": "<p>In this case, it is worth considering the use of Akka Cluster technology, which provides a fault-tolerant decentralized peer-to-peer based Cluster Membership Service with no single point of failure or single point of bottleneck. What does this mean in practice? Based on this technology, you can create distributed systems that could be dynamically scaled, not only when you want to achieve high availability (Akka CRDTs), but also data consistency (Akka Persistence + Sharding).</p>",
  "akka.cluster.practical.knowledge.content": "This workshop will give you a chance to learn Akka Cluster technology by a set of exercises which cover topics like starting, managing, splitting, healing the cluster. Besides getting to know a required theory to understand cluster functionalities, you will also learn how to set up and monitor the cluster in the production environment.",
  "akka.cluster.why.label": "Why Akka Cluster?",
  "akka.cluster.why.content": "Akka Cluster is a really great piece of software which solves very specific problems of distributed systems in a profound way. During the workshop, we will not only discuss use cases where this technology is a perfect match, but also point out when you should avoid using Akka Cluster. The second part is even more important, because from our experience programmers tend to overuse Akka Cluster for solutions that don't require it and will not benefit from it.",
  "akka.cluster.when.label": "When?",
  "akka.cluster.when.content": "Online workshop is coming soon. Join the mailing list and be the first to learn about the schedule and other details.",
  "akka.cluster.how.to.join.content": "Would you like to learn more about Akka Cluster training? Send us an email to <a href=\"mailto:{email}\">{email}</a>. We'll work out the details and send you a dedicated workshop plan for you and your team.",
  "akka.cluster.how.it.looks": "This is a 1 day training. In on-line version we use Zoom for voice and video communication and Slack for text chat.",
  "akka.cluster.next.item1.label": "Requirements",
  "akka.cluster.next.item1.content": "No previous knowledge of Akka stack is required.",
  "akka.cluster.next.item2.label": "",
  "akka.cluster.next.item2.content": "",

  "akka.cluster.agenda.chapter1": "Stateful vs stateless systems",
  "akka.cluster.agenda.chapter2": "Cluster bootstrap",
  "akka.cluster.agenda.chapter3": "Cluster sharding",
  "akka.cluster.agenda.chapter3.item1": "sharding entities",
  "akka.cluster.agenda.chapter3.item2": "sharding event sourced entities",
  "akka.cluster.agenda.chapter3.item3": "rebalancing",
  "akka.cluster.agenda.chapter3.item4": "passivation",
  "akka.cluster.agenda.chapter3.item5": "remember entities",
  "akka.cluster.agenda.chapter4": "Cluster management",
  "akka.cluster.agenda.chapter5": "Cluster failures",
  "akka.cluster.agenda.chapter5.item1": "healing strategies",
  "akka.cluster.agenda.chapter5.item2": "split brain",
  "akka.cluster.agenda.chapter6": "Cluster singleton",
  "akka.cluster.agenda.chapter7": "Distributed data (CRDTs)",
};

export type LanguageStrings = typeof baseRoutes;
export const en = baseRoutes;
