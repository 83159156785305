import * as React from 'react'
import { Navigation } from "../../PathHelper";
import { Link } from "react-router-dom";

type Props = Navigation & { onNext(): void }

const PageNavigation: React.FC<Props> = ({ previous, next, current, onNext }) => {

  return <div className="mt-2 mb-2 d-flex">
    <div className="mr-auto">
      <Link to={`/my-courses/${current.course}/${current.chapter}`}
            className="btn btn-outline-primary">
        <i className="fa fa-home" aria-hidden="true"/> Back to chapter
      </Link>
    </div>
    <div>
      {previous && <>
        <Link to={`/my-courses/${previous.course}/${previous.chapter}/${previous.page}`}
              className="btn btn-outline-primary">
          <i className="fa fa-step-backward" aria-hidden="true"/> Previous
        </Link>
      </>}
      {next && <>
        <Link onClick={onNext} to={`/my-courses/${next.course}/${next.chapter}/${next.page}`}
              className="btn btn-primary">
          Next <i className="fa fa-step-forward" aria-hidden="true"/>
        </Link>
      </>}
    </div>
  </div>
};

export default PageNavigation;
