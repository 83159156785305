import * as React from 'react';
import { useContext, useEffect } from 'react';
import { Switch } from 'react-router-dom';
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";
import { AppContext } from "../Contexts/AppContext";
import { CourseContext } from "../Contexts/CourseContext";
import { usePromise } from "react-use-promise-matcher";
import { Course } from "../types/Types";
import { getCourse } from "../CourseService/CourseService";
import CoursePage from "./CoursePage";
import ModuleRoute from "./ModuleRoute";

const CourseRoute: React.FC = (props: any) => {
  const { isLoggedIn } = useContext(AppContext);
  const { coursePath } = props.match.params;
  const { result, load } = usePromise<Course, Error>(() => getCourse(coursePath));

  useEffect(() => {
    load();
  }, []);

  return (
    <CourseContext.Provider value={result}>
      <Switch>
        <ProtectedRoute isLoggedIn={isLoggedIn} exact path={`${props.match.path}`}
                        component={CoursePage}/>
        <ProtectedRoute isLoggedIn={isLoggedIn} exact path={`${props.match.path}/:chapterPath`}
                        component={CoursePage}/>
        <ProtectedRoute isLoggedIn={isLoggedIn} path={`${props.match.path}/:chapterPath/:modulePath`}
                        component={ModuleRoute}/>
      </Switch>
    </CourseContext.Provider>
  )
};

export default CourseRoute;
