import * as React from "react";
import { FormattedMessage } from "react-intl";
import { TrainerDto } from "./common/types";

const Trainer: React.FC<{ data: TrainerDto }> = ({ data: { name, bioId, image, socialMedia } }) => {
  return (
    <div className="trainer-container">
      <div className="trainer-gradient">
        <div className="photo-container">
          <img src={`${process.env.PUBLIC_URL}/${image}`}></img>
        </div>
        <div className="about">
          <div><FormattedMessage id="workshop.trainer.about"/></div>
          <h1>{name}</h1>
          <h3><FormattedMessage id={bioId}/></h3>
          <div className="social-media">
            <a target="_blank" href={socialMedia?.twitter}><i className="fa fa-twitter"></i></a>
            <a target="_blank" href={socialMedia?.linkedIn}><i className="fa fa-linkedin"></i></a>
            <a target="_blank" href={socialMedia?.github}><i className="fa fa-github"></i></a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trainer;
