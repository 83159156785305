import { Course } from "../types/Types";

export type Paths = {
  course: string;
  chapter: string;
  module: string;
  page: string;
}

export type Navigation = {
  previous?: Paths;
  current: Paths;
  next?: Paths;
}

const resolvePaths = (course: Course, chapterPath: string, pagePath: string) => {
  const paths = course.chapters.flatMap(chapter => {
    return chapter.modules.flatMap(module => {
      return module.pages.map<Paths>(page => {
        return {
          course: course.path,
          chapter: chapter.path,
          module: module.path,
          page: page.path
        };
      })
    })
  });
  const found = paths.find(path => path.chapter === chapterPath && path.page === pagePath)!;
  const index = paths.indexOf(found);
  return {
    previous: index > 0 ? paths[index - 1] : undefined,
    current: found,
    next: index < paths.length - 1 ? paths[index + 1] : undefined
  } as Navigation;
};

export default resolvePaths;
