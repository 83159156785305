import React, { useCallback, useContext, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { login, setApiKey } from "../UserService/UserService";
import { Form, Formik } from "formik";
import * as Yup from 'yup';
import { AppContext } from "../Contexts/AppContext";
import TextField from "../common/TextField";

type FormValues = {
  loginOrEmail: string;
  password: string;
}

const initialValues: FormValues = { loginOrEmail: '', password: '' };

const validationSchema = Yup.object<FormValues>({
  loginOrEmail: Yup.string()
    .required('Login or Email Required'),
  password: Yup.string()
    .required('Password Required'),
});

type Props = {
  onLoggedIn: () => void;
  notifyError: (msg: string) => void;
}

const Login: React.FC<Props> = (props) => {
  const { isLoggedIn } = useContext(AppContext);
  const { notifyError, onLoggedIn } = props;
  const [isSubmitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(async ({ loginOrEmail, password }) => {
    setSubmitting(true);
    const loginResult = await login({ loginOrEmail, password });

    loginResult.fold({
      left: () => {
        notifyError('Incorrect login/email or password!');
      },
      right: ({ apiKey }) => {
        setApiKey(apiKey);
        onLoggedIn();
      }
    });
    setSubmitting(false);
  }, [notifyError, onLoggedIn]);

  return (
    isLoggedIn ? <Redirect to="/my-courses"/>
      : <div className="login-container">
        <Link to="/"><img className="sml-logo" src={`${process.env.PUBLIC_URL}/sml_logo_white.svg`}></img></Link>
        <div className="login-gradient">
          <div className="login-stripes"></div>
          <div className="login-box">
            <h4>Log in</h4>
            <div>
              <Formik initialValues={initialValues}
                      onSubmit={handleSubmit}
                      validationSchema={validationSchema}>
                <Form className="login-form">
                  <TextField type="text" name="loginOrEmail" placeholder="Login or Email" className="text-field"/>
                  <TextField type="password" name="password" placeholder="Password" className=""/>
                  <Link to="/recover-lost-password">Forgot password?</Link>
                  <input type="submit" value="Log in" className="login-button" disabled={isSubmitting}/>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Login;
