import React from "react";
import { Route, Redirect } from 'react-router-dom';

import { LanguageStrings } from "../localizations";
import { AppLanguages } from "../../common/Enums";
import { IntlProvider } from "react-intl";

type Props = {
  RouterComponent: React.ComponentClass<any>;
  languages: { [k: number]: string };
  appStrings: { [prop: string]: LanguageStrings }
  defaultLanguage?: AppLanguages
}

export const LocalizedRouter: React.FC<Props> = ({
  children,
  RouterComponent,
  appStrings,
  defaultLanguage
}) => (
  <RouterComponent>
    <Route path="/:lang([a-z]{2})">
      {({match, location }) => {

        const params = match ? match.params : {};
        const { lang = defaultLanguage || AppLanguages.English } = params;

        const { pathname } = location;
        if(!pathname.includes(`/${lang}/`)) {
          return <Redirect to={`/${lang}/`} />;
        }

        return (
          <IntlProvider locale={lang} messages={appStrings[lang]}>
            {children}
          </IntlProvider>
        )
  }}
  </Route>
  </RouterComponent>
);
