import { get, post, put } from "../Api/api";
import { Course, CourseInfo } from "../types/Types";

const basicUrl = '/api/v1/courses';

export const getUserCourses = async (): Promise<CourseInfo[]> => await get<CourseInfo[]>(`${basicUrl}/my-courses`);

export const getCourse = async (courseUrl: string) => await get<Course>(`${basicUrl}/${courseUrl}`);

export const redeemCode = (code: string) => post(`${basicUrl}/redeem-code`, { code });

export const toggleProgress = (coursePath: string, chapterPath: string, modulePath: string, pagePath: string) =>
  put(`${basicUrl}/${coursePath}/${chapterPath}/${modulePath}/${pagePath}/progress`);
