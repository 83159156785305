import { ChangePasswordData, LoginData, User, UserRegistrationData } from "../types/Types";
import { post, get } from "../Api/api";
import { Either, Left, Right } from "ts-matches";

const userUrl = '/api/v1/user';

export const setApiKey = (apiKey: string) => localStorage.setItem('apiKey', apiKey);

export const registerUser = async ({ login, email, password }: UserRegistrationData): Promise<Either<string, { apiKey: string }>> => {
  try {
    const data = await post<UserRegistrationData, { apiKey: string }>(
      `${userUrl}/register`,
      { login, email, password },
      { securedRequest: false });
    return Right.of(data);
  } catch (error) {
    return Left.of(error);
  }
};

export const login = async ({ loginOrEmail, password }: LoginData): Promise<Either<string, { apiKey: string }>> => {
  try {
    const data = await post<LoginData & { apiKeyValidHours: number }, { apiKey: string }>(
      `${userUrl}/login`,
      { loginOrEmail, password, apiKeyValidHours: 1 },
      { securedRequest: false });
    return Right.of(data);
  } catch (error) {
    return Left.of(error.message);
  }
};

export const getCurrentUser = async (): Promise<Either<string, User>> => {
  try {
    const data = await get<User>(userUrl);
    return Right.of(data);
  } catch (error) {
    return Left.of(error);
  }
};

export const changeProfileDetails = async ({ email, login }: User): Promise<Either<string, User>> => {
  try {
    const data = await post<User, User>(userUrl, { email, login });
    return Right.of(data);
  } catch (error) {
    return Left.of(error);
  }
};

export const changePassword = async ({ currentPassword, newPassword }: ChangePasswordData): Promise<Either<string, string>> => {
  try {
    const data = await post<ChangePasswordData, string>(`${userUrl}/changepassword`, { currentPassword, newPassword });
    return Right.of(data);
  } catch (error) {
    return Left.of(error);
  }
};
