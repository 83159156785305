import * as React from "react";

const Testimonials: React.FC = () => {
  return (
    <div className="testimonials">
      <div className="quote-container">
        <h3><i>"In theory, there is no difference between theory and practice.</i></h3>
        <h3><i>But, in practice, there is." <b>Jan L. A. van de Snepscheut</b></i></h3>
      </div>
    </div>
  )
};

export default Testimonials;
