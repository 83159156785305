import * as React from 'react';
import { CourseChapter } from "../types/Types";
import VideoPlayer from "./VideoPlayer/VideoPlayer";
import ProgressBar from "./ProgressBar/ProgressBar";
import { Route, Link, Switch, RouteComponentProps } from 'react-router-dom';
import Chapter from "./Chapter/Chapter";
import styles from './CoursePage.module.scss';
import { useContext } from "react";
import { CourseContext } from "../Contexts/CourseContext";
import Spinner from "../Spinner/Spinner";

type ChapterInfoProps = {
  coursePath: string,
  chapter: CourseChapter
};

const ChapterInfo: React.FC<ChapterInfoProps> = ({ coursePath, chapter }) => {
  const modulesNum = chapter.modules.length;
  return (
    <Link to={`/my-courses/${coursePath}/${chapter.path}`}>
      <ProgressBar chapter={chapter}/>
      <div>{chapter.title}</div>
      <div className={styles.modulesLength}>
        {modulesNum === 1 ? '1 MODULE' : `${modulesNum} MODULES`}</div>
    </Link>
  )
};

type CoursePageProps = {} & RouteComponentProps<{ chapterPath: string }>;

const CoursePage: React.FC<CoursePageProps> = ({ match }) => {
  const { chapterPath } = match.params;
  const course = useContext(CourseContext);

  return course.match({
    Loading: () => <Spinner/>,
    Rejected: (e) => <>{e}</>,
    Resolved: (course) => <div className="container">
      <VideoPlayer link="https://vimeo.com/423049232"/>
      <div className={styles.tileContainer}>
        <h2>{course.name}</h2>
      </div>
      <div className={`${styles.tileContainer} ${styles.chapterInfoContainer}`}>
        {course.chapters.map(chapter =>
          <div className={`${styles.chapterInfo} ${chapterPath === chapter.path ? styles.currentChapter : ''}`}>
            <ChapterInfo coursePath={course.path} chapter={chapter}/>
          </div>)}
      </div>
      <div>
        <Switch>
          {course.chapters.map(chapter =>
            <Route path={`/my-courses/${course.path}/${chapter.path}`}
                   render={() => <Chapter chapter={chapter} coursePath={course.path}/>}/>)}
        </Switch>
      </div>
    </div>
  });
};

export default CoursePage;
