import * as React from "react";
import { Link } from 'react-router-dom';
import Trainer from "./Trainer";
import Testimonials from "./Testimonials";
import Menu from "./Menu";
import Agenda from "./common/Agenda/Agenda";
import { gatlingDescription, gatlingAgenda, andrzej } from './data';
import WorkshopDescription from "./common/WorkshopDescription/WorkshopDescription";
import { FormattedMessage } from "react-intl";
import WorkshopHeader from "./common/Header/WorkshopHeader";
import OtherWorkshops from "../Welcome/Workshops/OtherWorkshops";

const GatlingCourse: React.FC = () => {
  return (
    <div className="gatling-container">
      <WorkshopHeader titleId="gatling.title"
                      subtitleId="gatling.subtitle"
                      trainerImage="andrzej.png"
                      mailchimp="https://mailchi.mp/softwaremill.com/soihprbkb7"
      />
      <Menu/>
      <div id="content" className="workshop-content">
        <WorkshopDescription data={gatlingDescription}/>

        <div id="videos" className="videos">
          <h1><FormattedMessage id="workshop.videos"/></h1>
          <div className="videos-container">
            <Link to={{ pathname: "https://youtu.be/Jln3xsTpCs8" }} target="_parent">
              <div className="youtube-outer-container">
                <img className="video-intro-image" src={require("../assets/gatling_movie1.png")}
                     alt="Performance tests in Gatling 1"></img>
                <div className="youtube-inner-container">
                  <img className="youtube-button" src={require("../assets/youtube.png")}></img>
                </div>
              </div>

            </Link>
            <Link to={{ pathname: "https://youtu.be/FITKxH2Qv6c" }} target="_parent">
            <div className="youtube-outer-container">
              <img className="video-intro-image" src={require("../assets/gatling_movie2.png")}
                   alt="Performance tests in Gatling 2"></img>
              <div className="youtube-inner-container">
                <img className="youtube-button" src={require("../assets/youtube.png")}></img>
              </div>
            </div>
            </Link>
            <Link to={{ pathname: "https://youtu.be/vFtlUKLQP9U" }} target="_parent">
            <div className="youtube-outer-container">
              <img className="video-intro-image" src={require("../assets/gatling_movie3.png")}
                   alt="Performance tests in Gatling 3"></img>
              <div className="youtube-inner-container">
                <img className="youtube-button" src={require("../assets/youtube.png")}></img>
              </div>
            </div>
            </Link>
          </div>
        </div>
        <div id="resources" className="resources">
          <h1><FormattedMessage id="workshop.resources"/></h1>
        </div>
        <div id="agenda">
          <Agenda data={gatlingAgenda}/>
        </div>
      </div>
      <div id="trainer">
        <Trainer data={andrzej}/>
      </div>
      <Testimonials/>
      <OtherWorkshops theme="Light"/>
    </div>
  );
};

export default GatlingCourse;
