import { useField } from "formik";
import React from "react";

const TextField = ({ label, ...props }: any) => {
  const [field, meta] = useField(props);

  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <input {...field} {...props} />
      {meta.touched && meta.error ? <div className="validation-message">{meta.error}</div> : null}
    </>
  );
};

export default TextField
