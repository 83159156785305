import React, { useCallback, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { claimPasswordReset } from "../PasswordService/PasswordService";
import TextField from "../common/TextField";

type Props = {
  notifySuccess: (msg: string) => void;
  notifyError: (msg: string) => void;
}

interface FormFields {
  loginOrEmail: string;
}

const validationSchema = Yup.object<FormFields>({
  loginOrEmail: Yup.string()
    .required('Email or login required')
});

const RecoverLostPassword: React.FC<Props> = props => {
  const { notifySuccess, notifyError } = props;
  const [resetComplete, setResetComplete] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(async formValues => {
    setSubmitting(true);
    (await claimPasswordReset(formValues.loginOrEmail)).fold({
      left: () => {
        notifyError('Could not claim password reset!');
        setSubmitting(false);
      },
      right: () => {
        notifySuccess('Password reset claim success.');
        setSubmitting(false);
        setResetComplete(true);
      }
    });
  }, [notifySuccess, notifyError]);

  return (
    resetComplete ? <Redirect to="/login"/>
      : <div className="RecoverLostPassword">
        <Formik initialValues={{ loginOrEmail: '' }}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}>
          <Form className="common-form">
            <TextField type="text" name="loginOrEmail" placeholder="Email or login" className="form-control"/>
            <input type="submit" value="Recover password" className="btn btn-primary"
                   disabled={isSubmitting}/>
          </Form>
        </Formik>
      </div>
  );
};

export default RecoverLostPassword;
