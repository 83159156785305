import * as React from 'react';
import { CourseChapter } from "../../types/Types";
import Module from "../Module/Module";

type Props = {
  chapter: CourseChapter;
  coursePath: string;
}

const Chapter: React.FC<Props> = ({ chapter, coursePath }) => {
  return (
    <div>
      {chapter.modules.map(module => <Module module={module} coursePath={coursePath} chapterPath={chapter.path}/>)}
    </div>
  )
};

export default Chapter;
