import * as React from 'react';
import { useCallback, useContext } from 'react';
import { ModulePage } from "../../../types/Types";
import { Maybe, Some } from "ts-matches";
import { CourseContext } from "../../../Contexts/CourseContext";
import Spinner from "../../../Spinner/Spinner";
import ErrorMessage from "../../../common/ErrorMessage";
import VideoPlayer from "../../VideoPlayer/VideoPlayer";
import { toggleProgress } from "../../../CourseService/CourseService";
import resolvePaths, { Navigation } from "../../PathHelper";
import PageNavigation from "./PageNavigation";
import { RouteComponentProps } from 'react-router-dom';

type PageProps = {
  page: Maybe<ModulePage>;
} & RouteComponentProps<{ chapterPath: string, pagePath: string }>;

const Page: React.FC<PageProps> = ({ page, match }) => {
  const { chapterPath, pagePath } = match.params;
  const result = useContext(CourseContext);

  const createToggleHandler = useCallback((page: ModulePage, navigation: Navigation) => () => {
    const { current } = navigation;

    if (!page.finished) {
      toggleProgress(current.course, current.chapter, current.module, current.page);
      page.finished = true;
    }
  }, []);

  return result.match({
    Loading: () => <Spinner/>,
    Rejected: (e: Error) => <ErrorMessage error={e.message}/>,
    Resolved: course => {
      const chapter = Some.of(course.chapters.find(c => c.path === chapterPath));
      const module = chapter.map(c => c.modules.find(m => m.pages.find(p => p.path === pagePath)));
      const page = module.map(m => m.pages.find(p => p.path === pagePath));
      const navigation = resolvePaths(course, chapterPath, pagePath);

      return page.fold({
        none: () => <ErrorMessage error="Error: Page content wasn't properly loaded. Please try again!"/>,
        some: page =>
          <div className="container">
            <PageNavigation {...navigation} onNext={createToggleHandler(page, navigation)}/>
            <VideoPlayer onEnded={createToggleHandler(page, navigation)} link="https://vimeo.com/423049232"/>
          </div>
      })
    }
  })
};

export default Page;
