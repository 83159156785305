import * as React from 'react';
import { useEffect } from "react";
import CourseCard from "./CourseCard";
import { CourseInfo } from "../types/Types";
import { getUserCourses } from "../CourseService/CourseService";
import Spinner from "../Spinner/Spinner";
import styles from './MyCourses.module.scss';
import { usePromise } from "react-use-promise-matcher";
import ErrorMessage from "../common/ErrorMessage";

const MyCourses: React.FC = () => {
  const { result, load } = usePromise<CourseInfo[]>(() => getUserCourses());

  useEffect(() => {
    load();
  }, []);

  return result.match({
    Loading: () => <Spinner/>,
    Rejected: (e) => <ErrorMessage error={e}/>,
    Resolved: courses => <div>
      <div className="container">
        <h1>My courses</h1>
        <div className="row justify-content-md-start">
          {courses.map((c, ix) => <div className={`col-md-auto ${styles.cardTopMargin}`} key={ix}>
            <CourseCard course={c}/></div>)}
        </div>
      </div>
    </div>
  });
};

export default MyCourses;
