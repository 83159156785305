import { get } from '../Api/api';
import { Version } from "../types/Types";
import { Either, Left, Right } from "ts-matches";

export const getAppVersion = async (): Promise<Either<string, Version>> => {
  try {
    const version = await get<Version>("/admin/version");
    return Right.of(version);
  } catch (error) {
    return Left.of(error);
  }
};
