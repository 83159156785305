import { post } from "../Api/api";
import { Either, Left, Right } from "ts-matches";

const basicUrl = 'api/v1/passwordreset';

export const claimPasswordReset = async (loginOrEmail: string): Promise<Either<string, void>> => {
  try {
    await post(`${basicUrl}/forgot`, { loginOrEmail });
    return Right.of(undefined);
  } catch (error) {
    return Left.of(error);
  }
};

export const resetPassword = (code: string, password: string) => post(`${basicUrl}/reset`, { code, password });
